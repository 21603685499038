
































































































































































































































































































































































































































@import "@/styles/basics/globals.scss";

#stock-info {
  width: 100%;
}

.info-content-container {
  max-width: 600px;
  margin: 20px auto;
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--color-background);
  box-shadow: 0 2px 10px rgba(15, 15, 26, 0.08);
  width: 85%;
}

.info-ticker-box {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.text-info-ticker {
  text-align: center;
  background-color: var(--color-surface, #F3F4F7);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding: 8px 30px;
  border-radius: 20px;
  color: var(--color-text-primary);
  font-weight: 500;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.text-bold {
  font-weight: 700;
  color: var(--primary, #00aedd);
}

.info-sections {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.info-section {
  background-color: var(--color-surface, #F3F4F7);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(15, 15, 26, 0.05);
  transition: transform 0.2s, box-shadow 0.2s;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(15, 15, 26, 0.08);
  }
}

.section-header {
  background-color: var(--color-active-surface, rgba(0, 174, 221, 0.08));
  padding: 12px 20px;
  border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.08));
  
  h3 {
    margin: 0;
    color: var(--primary, #00aedd);
    font-size: 16px;
    font-weight: 600;
    text-align: left;
  }
}

.section-content {
  padding: 15px 20px;
}

.info-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px 0;
  border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.04));
  
  &:last-child {
    border-bottom: none;
  }
}

.info-label {
  color: var(--color-text-secondary, #606577);
  font-size: 14px;
  font-weight: 500;
  flex-shrink: 0;
  margin-right: 15px;
  width: 40%;
  text-align: left;
}

.info-value {
  color: var(--color-text-primary);
  font-size: 14px;
  font-weight: 600;
  text-align: right;
  word-break: break-word;
  
  &.industry-value {
    white-space: normal;
    line-height: 1.4;
  }
  
  &.important {
    color: var(--primary, #00aedd);
    font-weight: 700;
  }
  
  &.highlight {
    background-color: var(--color-active-surface, rgba(0, 174, 221, 0.08));
    padding: 4px 10px;
    border-radius: 12px;
    color: var(--primary, #00aedd);
    display: inline-block;
  }
}

.description-container {
  margin-top: 5px;
}

.description-header {
  border-bottom: none;
  padding-bottom: 0;
}

.description-content {
  background-color: var(--color-background);
  border-radius: 6px;
  padding: 15px;
  margin-top: 10px;
  border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.08));
}

.description-text {
  text-align: left;
  font-size: 14px;
  line-height: 1.6;
  color: var(--color-text-primary);
  margin: 0;
}

.description-first, .description-rest {
  color: var(--color-text-primary) !important;
}

#remaining-text {
  display: none;
}

.read-more-btn-container {
  text-align: center;
  margin-top: 15px;
}

.read-more-btn {
  background-color: var(--color-active-surface, rgba(0, 174, 221, 0.08));
  border: none;
  border-radius: 15px;
  cursor: pointer;
  color: var(--primary, #00aedd);
  font-size: 14px;
  padding: 6px 16px;
  transition: all 0.2s;
  font-weight: 500;
  
  &:hover {
    background-color: var(--color-active-surface, rgba(0, 174, 221, 0.15));
    color: var(--primary, #00aedd);
  }
  
  .read-more-text {
    position: relative;
    
    &:before, &:after {
      content: "-";
      position: relative;
      display: inline-block;
      margin: 0 5px;
    }
  }
}

.brain-section {
  text-align: center;
}

.brain-score {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  
  &.eye-option {
    padding: 15px 0;
  }
}

.score-circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--primary, #00aedd);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  
  &.disabled {
    background-color: var(--color-muted, #e9ecf1);
  }
}

.score-value {
  color: white;
  font-size: 28px;
  font-weight: 700;
}

.score-label {
  font-size: 14px;
  color: var(--color-text-secondary, #606577);
  margin: 0;
}

.not-available .score-label {
  color: var(--warning, #FF9B00);
  margin-bottom: 10px;
}

.upgrade-link {
  color: var(--primary, #00aedd);
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
  
  &:hover {
    text-decoration: underline;
  }
}

.view-score-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-active-surface, rgba(0, 174, 221, 0.08));
  border: none;
  border-radius: 8px;
  cursor: pointer;
  color: var(--primary, #00aedd);
  font-size: 14px;
  padding: 15px 20px;
  transition: all 0.2s;
  font-weight: 500;
  
  i {
    font-size: 32px;
    margin-bottom: 8px;
  }
  
  span {
    font-size: 13px;
  }
  
  &:hover {
    background-color: var(--color-active-surface, rgba(0, 174, 221, 0.15));
  }
}

/* Mobile responsiveness */
@media (max-width: 767px) {
  .info-content-container {
    padding: 15px;
    width: 92%;
  }
  
  .info-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    padding: 10px 0;
  }
  
  .info-label {
    font-weight: 600;
    margin-bottom: 4px;
    width: 100%;
  }
  
  .info-value {
    width: 100%;
    text-align: left;
  }
  
  .score-circle {
    width: 70px;
    height: 70px;
  }
  
  .score-value {
    font-size: 24px;
  }
  
  .section-header h3 {
    font-size: 15px;
  }
}
