





































































































































































.platinum-score-container{
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  background-color: #f3f4f7;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.platinum-main-container{
  /*position: fixed;
  left: 25%;
  transform: translate(-50%, 0);*/
  margin-left: 10px;
}
.plat-score-btn{
  background-color: #00add8;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  color: white;
  width: 150px;
  height: 30px;
  margin-top: 5px;

}

.attempts-remaining-container{
  margin-top: 50px;
  font-size: 14px;
}

.attempts-style{
  color: grey;
}

.score-container{
  margin-top: 20px;
  background-color: #00add8;
  width: 80px;
  margin-left: 165px;
  text-align: center;
}

.btn-container{
  display:flex;
  text-align: center;
}

.score-number{
  font-size: 25px;
  color: white;
}

.name-style{
  font-size: 15px;
  color: white;
  font-weight: bold;
}

.score-style{
  color: white;
}

.circle{
  background-color: red;
  width: 20px;
  color: white;
  border-radius: 30px;
  text-align: center;
  position: absolute;
  margin-left: 132px;
  font-weight: bold;
  font-size: 15px;
  margin-top: -5px;
}

.plat-comp-style{
  color: var(--color-custom-typo-plat-mobile);
}

.plat-comp-container{
  height: 40px;
  background-color: var(--color-custom-plat-mobile);
  text-align: center;
}

.plat-comp-number-style{
  color: var(--color-custom-typo-plat-mobile);
  font-weight: bold;
  font-size: 23px;
}

.info-ticker-box {
   width: 100%;
   display: flex;
   justify-content: center;
}
.text-info-ticker {
  text-align: center;
  background-color: #F3F4F7;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 24px;
  width: 130px;
  padding: 7px 20px;
  color: black;
}

.text-bold {
  font-weight: bold;
}

.plat-button-container{
  display: flex;
  gap: 5px;
  justify-content: center;
}

.not-allowed-platinum-container{
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  background-color: #f3f4f7;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

