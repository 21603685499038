






















































































































































































@import "@/styles/basics/globals.scss";

/* Fix for Highcharts responsive layout */
:deep(.highcharts-container),
:deep(.highcharts-root) {
  width: 100% !important;
  height: 100% !important;
  overflow: visible !important;
}

:deep(.highcharts-series-group) {
  overflow: visible !important;
}

:deep(.highcharts-point) {
  stroke-width: 0;
}

:deep(.highcharts-grid-line) {
  stroke-dasharray: 2, 2;
}

:deep(.highcharts-plot-border) {
  stroke-width: 0;
}
