













































































































































































































































































































































































































































































.connect-btn,
.send-order-btn{
  border: none;
  border-radius: 20px;
  background-color: #00aedd;
  color: white;
  cursor: pointer;
  width: 170px;
  height: 40px;
}

.open-account-text{
  color: #1f7aa0;
  font-weight: bold;
  text-decoration: none;
}

.legal-warnings-text,
.terms-of-use-text{
  text-decoration: none;
  color: #00aedd;
}

.difbroker-text{
  text-decoration: none;
  color: #1f7aa0;
  font-weight: bold;
}

.ticker-header {
  background-color: var(--color-surface);
  width: 250px;
  height: 36px;
  margin: 0 auto 20px auto;
  padding: 8px 0;
  border-radius: 6px;
  box-shadow: 0 1px 2px rgba(15, 15, 26, 0.05);
}

.ticker-value {
  font-size: 14px;
  color: var(--color-text-primary);
  font-weight: 500;
}

.ticker-value strong {
  font-weight: 700;
  color: var(--primary);
}

.ticker-container{
  background-color: var(--custom-color-right-menu-highlight); 
  width: 250px;
  height: 30px;
  margin: auto;
  padding-top: 10px;
}

.disconnected-container{
  background-color: #df2f4f;
  color: white;
  text-align:center; 
  border-radius:30px; 
  margin:0 40px 0 40px;
}

.separator{
  border-top: 1px solid #898d9d;
  opacity: 0.3;
  width: 90%;
  margin: auto;
  margin-top: 35px;
}

.send-order-container{
  margin-top: 40px;
}

.disconect-broker-btn{
  color: #df2f4f;
  border: none;
  cursor: pointer;
  background-color: transparent;
  font-size: 15px;
}

.disconnect-broker-container{
  margin-top: 20px;
  margin-left: 110px;
}

.select-style{
  width: 350px;
  margin: auto;
}

.account-title-container{
  margin-top: 30px;
}

.connected-container{
  background-color: #57a869; 
  text-align: center; 
  border-radius: 30px; 
  margin: 10px 40px 0px 40px;
}

.header-divs-container{
  display: inline-flex;
}

.close_btn{
  background-color: transparent;
  border: none;
  color: white;
  margin-top: 10px;
  margin-left: 30px;
  cursor: pointer;
}

.container-broker-connection {
  display: flex;
  align-items: center;
  background-color: var(--color-surface);
  border-radius: 6px;
  padding: 15px;
  margin: 15px 0;
  border-left: 4px solid var(--warning);
}

.checkbox-container {
  margin-right: 15px;
  display: flex;
  align-items: center;
}

.checkbox-style {
  transform: scale(1.3);
  margin: 0;
}

.info-text-container{
  flex: 1;
  margin-right: 10px;  
}

.edit-btn{
  border: none;
  background-color: transparent;
  font-weight: bold;
  color: #0e5f85;
  cursor: pointer;
  margin-top: 10px;
}

.send_order_btn_style{
  border: none;
  border-radius: 20px;
  background-color: #00aedd;
  color: white;
  cursor: pointer;
  width: 170px;
  height: 40px;
  font-weight: bold;
}

.send-order-info-container{
  max-width: 600px;
  margin: 20px auto;
  margin-bottom: 20px;
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--color-background);
  box-shadow: 0 2px 10px rgba(15, 15, 26, 0.08);
  width: 85%;
}

.info-ticker{
  background-color: var(--color-surface);
  border-radius: 6px;
  padding: 15px 20px;
  margin: 15px 0;
}

.info-text-style{
  text-align: left;
  font-size: 12px;
}

.btns-container{
  margin: 25px 0;
  text-align: center;
}

.btns-container button {
  margin: 5px 0;
}

a{
  color: #0fafdb;
}

#broker-connection span, #broker-connection h3{
  color: var(--color-text-primary);
}

.not-allowed-order{
  text-align: center;
  margin-top: 20px;
  color: #FF9B00;
  font-weight: 500;
}

.not-available-data-container{
  text-align: center;
}

.not-available-data{
  color: #FF9B00;
}

.link-to-store{
  color: #35add8;
}

.info-ticker-box {
   width: 100%;
   display: flex;
   justify-content: center;
}

.text-info-ticker {
  text-align: center;
  background-color: #F3F4F7;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 16px;
  padding: 7px 25px;
  border-radius: 4px;

  span {
    font-size: 18px;
  }
}

.text-bold{
  font-weight: bold;
}

.activotrade-warning {
  margin-top: 20px;
  padding: 15px;
  background-color: #fef8e8;
  border-left: 4px solid #f0ad4e;
  color: #856404;
  border-radius: 4px;
  text-align: left;
}

.activotrade-warning p {
  margin: 5px 0;
}

.select-wrapper {
  position: relative;
  width: 100%;
  max-width: 350px;
  margin: auto;
  margin-top: 5px;
}

.styled-select {
  width: 100%;
  height: 40px;
  padding: 10px 15px;
  font-size: 16px;
  color: var(--color-text-primary);
  background-color: var(--color-background);
  border: 1px solid var(--color-outline);
  border-radius: 4px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  outline: none;
  transition: border-color 0.3s;
}

.styled-select:focus {
  border-color: var(--primary);
  box-shadow: 0 0 0 2px rgba(0, 174, 221, 0.1);
}

.select-wrapper::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid var(--color-text-secondary);
  pointer-events: none;
}

.broker-disconnected-options p {
  margin-bottom: 10px;
  font-size: 14px;
  text-align: center;
}

.action-btn {
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  padding: 0 16px;
  height: 40px;
  transition: all 0.2s ease;
  min-width: 200px;
  font-weight: 600;
}

.primary-btn {
  background-color: var(--primary);
  color: white;
  box-shadow: none;
}

.primary-btn:hover {
  background-color: var(--color-muted, var(--color-highlight));
  box-shadow: none;
}

.secondary-btn {
  background-color: var(--color-surface);
  color: var(--color-text-primary);
  border: 1px solid var(--color-outline);
}

.secondary-btn:hover {
  background-color: var(--color-muted, #e9ecf1);
  color: var(--color-highlight);
}

.selected-service {
  color: var(--primary);
  font-size: 18px;
  font-weight: 600;
  padding: 8px 16px;
  background-color: var(--color-active-surface);
  border-radius: 4px;
  display: inline-block;
}

.service-selection-container {
  max-width: 600px;
  margin: 20px auto;
  margin-bottom: 20px;
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--color-background);
  box-shadow: 0 2px 10px rgba(15, 15, 26, 0.08);
  width: 85%;
}

.selection-header {
  font-size: 20px;
  margin-bottom: 20px;
  color: var(--color-text-primary);
  text-align: center;
}

.info-panel {
  background-color: var(--color-surface);
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 25px;
  border-left: 4px solid var(--primary);
}

.info-panel p {
  margin: 10px 0;
  line-height: 1.5;
  font-size: 13px;
  color: var(--color-text-primary);
}

.broker-selector {
  margin-top: 25px;
  text-align: center;
}

.broker-selector p {
  margin-bottom: 12px;
  font-size: 15px;
  color: var(--color-text-primary);
}

/* Order resume styling */
.order-resume-header {
  background-color: var(--color-surface);
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 20px;
  border-left: 4px solid var(--primary);
  text-align: center;
}

.order-resume-header p {
  margin: 0;
  font-size: 16px;
  color: var(--color-text-primary);
  font-weight: 600;
}

.info-ticker p {
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: var(--color-text-primary);
}

.info-ticker span {
  color: var(--color-text-secondary);
}

.info-text-container p {
  color: var(--color-text-primary);
}

.important-notice {
  background-color: var(--color-surface);
  border-radius: 6px;
  padding: 15px;
  margin: 15px 0;
  border-left: 4px solid var(--warning);
}

.important-notice p {
  color: var(--color-text-primary);
}

.important-notice p strong {
  color: var(--error);
  margin-right: 5px;
}

.url-copy-container {
  margin: 15px 0;
  padding: 0 1px;
}

.url-textarea {
  width: calc(100% - 20px);
  min-height: 80px;
  padding: 10px;
  border: 1px solid var(--color-outline);
  border-radius: 6px;
  font-family: inherit;
  font-size: 14px;
  color: var(--color-text-primary);
  background-color: var(--color-surface);
  resize: vertical;
}

.copy-instructions {
  background-color: var(--color-surface);
  border-radius: 6px;
  padding: 15px;
  margin: 15px 0 0 0;
}

.copy-instructions p {
  margin: 0;
  font-size: 13px;
  color: var(--color-text-primary);
}

.connected-content-container {
  max-width: 600px;
  margin: 20px auto;
  margin-bottom: 20px;
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--color-background);
  box-shadow: 0 2px 10px rgba(15, 15, 26, 0.08);
  width: 85%;
}

.ticker-info-container {
  background-color: var(--color-surface);
  border-radius: 6px;
  padding: 15px 20px;
  margin: 15px 0;
}

.ticker-info-container p {
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: var(--color-text-primary);
}

.ticker-info-container span {
  color: var(--color-text-secondary);
}

.no-select-mode {
  margin-top: 10px;
  padding: 15px;
  background-color: var(--color-surface);
  border-radius: 6px;
  border-left: 4px solid var(--primary);
}

.activotrade-fixed-option {
  margin-bottom: 15px;
  text-align: center;
}

.activotrade-label {
  font-size: 16px;
  font-weight: 600;
  color: var(--primary);
  margin-bottom: 10px;
  display: block;
}

.connect-activotrade {
  margin-top: 10px;
}

.no-select-info, .no-link-info {
  margin-top: 10px;
  padding: 10px;
  background-color: rgba(255, 155, 0, 0.1);
  border-radius: 4px;
}

.warning-text {
  color: var(--warning, #FF9B00) !important;
  font-weight: 500;
}

