





















































































































































































































































































































#searches-container{
    margin-top: 15px;
    height: 480px;
    min-height: 480px;
    overflow-y: scroll;
}

@media (min-width: 901px) {
.searches-containerr {
    position: absolute;
    min-height: 500px;
    width: 320px;
    background: #F3F4F7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    top: 348px;
    left: 150px;
    border-radius: 10px;
    margin-top: 0 !important;
}
.searches-container:before {
    border: 25px solid #F3F4F7;
    content: '';
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-top-color: transparent;
    position: absolute;
    left: -40px;
    bottom: 325px;
    border-radius: 10px;
}
}

.saved-searches-container{
    display: flex;
}
.title-search{
    font-weight: bold;
    font-size: 14px;
    margin-left: 51px;
}
.material-icons-outlined{
    cursor: pointer;
}
.icon-close {
    margin-top: 12px !important;
}
.input-search-value{
    width:200px; 
}
.search-items-container{
    display:block; 
    margin: 0 0 0 50px; 
    font-size:14px;
}
.link-text{
    color: black;
    text-decoration: none;
    line-height : 40px;
}
.link-text :visited{
    color:black;
}
hr{
    width: 85%;
    border-top: 1px solid grey;
    opacity: 0.3;
    border-bottom: none;
    margin-top: 1px;
}
#workspace-favorites-fullscreen {
    position: fixed;
    top: 0px;
    background: rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 100%;
    z-index: 9999;
    left: 0px;
}
.personal-searches :hover{
    cursor: pointer;
}
.save-search-btn{
    background-color: #00aedd;
    color: white;
    border-radius: 15px;
    font-size: 11px;
    font-weight: bold;
    border: none;
    width: 190px;
    height: 30px;
    margin-top: 10px;
    cursor:pointer;
}
.input-accept-button{
    width:83px;
    height:32px;
    color: white;
    background-color:#00aedd;
    border:none;    
    border-radius: 25px;
    margin-left: 15px;
    font-weight: bold;
    cursor:pointer;
}
.input-cancel-button{
    width:83px;
    height:32px;
    color: #0E5F85;
    border:none;    
    background-color: transparent;
    border-radius: 25px;
    margin-left: 15px;
    font-weight: bold;
    cursor:pointer;
}
.names-items-container{
    margin-left:50px;
}
.saved-search{
    margin-left:50px;
    display:inline;
    margin-top: 8px;
}
.add-search{
    margin-bottom: 20px;
    display:flex;
    justify-content: center;
    align-items: center;
}
.save-button-container{
    text-align: center; 
}
.delete-search-container{
    text-align: center; 
    margin-top: 120px;
}
.personal-searches:hover{
    color:#00aedd;
    cursor:pointer;
}
.delete-question-text{
    font-weight: bold;
    font-size: 14px;
}
.searches-delete-container{
    display: inline-flex;
}
.inputs-container{
    display: inline-grid;
}
.search-name-delete{
    margin-left: 5px;
}

.header-container{
    display: flex;
    justify-content: center;
    padding: 10px 0px 5px 0px !important;
}

.title-container{
    margin-left: 30px;
    font-size: 20px;
    font-weight: 600;
}

.close-btn-container{
    margin-left: auto;
    order: 2;
    margin-right: 10px;
}

.not-allowed-searches{
    text-align: center;
    margin-top: 20px;
    color: #FF9B00;
font-weight: 500;
}
