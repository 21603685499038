


































































































































































































































































































































































































































.ticker-name-container{
  background-color: #f3f4f7; 
  width: 250px;
  height: 40px;
  margin: auto;
  text-align: center;
}
.ticker-name-container p{
  padding-top: 10px;
}

.container-alarm{
  margin-left: 5px;
  border: 1px solid lightgrey;
  border-left: 5px solid #42a0c9;
  display: block;
  margin-bottom: 10px;
  border-radius: 5px;
}

.container-note{
  margin-left: 5px;
  border: 1px solid lightgrey;
  border-left: 5px solid rgba(73, 76, 90, 0.25);
  display: block;
  margin-bottom: 10px;
  border-radius: 5px;
}

.container-portfolio{
  margin-left: 5px;
  border: 1px solid lightgrey;
  border-left: 5px solid #BA91BB;
  display: block;
  margin-bottom: 10px;
  border-radius: 5px;
}

.container-favorite{
  margin-left: 5px;
  border: 1px solid lightgrey;
  border-left: 5px solid #E39B0F;
  display: block;
  margin-bottom: 10px;
  border-radius: 5px;
}

.comment-box {
  display: flex;
  align-items: center;
  margin: 20px 0px;
  width:95%;
  margin-left:10px;
}

.registry-date{
  width: 170px;
  height: 24px;

  /* Subtitle 2 */

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 0.1px;

  /* Tema Claro/02. Primary/900 */

  color: #000010;

  padding: 10px 0 0 15px;
}

.registry-text{
  width: 341px;

  /* Caption */

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* or 133% */

  letter-spacing: 0.4px;

  /* Tema Claro/02. Primary/900 */

  color: #000010;

  padding: 5px 0 15px 15px;
}

.entries-container-mobile{
  overflow-y: scroll;
  margin-top: 215px;
}

.ticker-and-entries {
    display: flex;
    flex-direction: column;
}

.header-container-registry{
  display: flex;
  justify-content: center;
  padding: 30px 0px 35px 0px !important;
}

.title-container-registry{
  margin-left: 30px;
  font-size: 20px;
  font-weight: 600;
}

.close-btn-container-registry{
  margin-left: auto;
  order: 2;
  margin-right: 10px;
}

.fixed-top{
  position: fixed;
  top: 70px;
}

.filter-buttons-container{
  display: flex;
  margin: 20px 0 20px 0;
}

