$sidebar: 64px;
$open_sidebar: 315px;
$eurekers_color: #00add8;
$main_color: #ebebeb;
$main_color_white: #ffffff;
$border_color: #2f3241;
.stockchart-menu-secundario {
  position: absolute;
  right: 0px;
  background: $main_color;
  border-right: 1px solid $main_color_white;
  min-width: 64px;
  z-index: 1;

  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);

  ul {
    padding: 0;
    li {
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.12);
      border-color: var(--color-custom-border);
    }
  }
}

.md-32 {
  font-size: 32px;
  color: #464646;
}

.mdc-list-item {
  justify-content: center;
  width: 80px;
  padding: 10px 0;
  margin: 0;
}