














































































































































































































































































































































































@use "@material/list/mdc-list";
@use "@material/menu/mdc-menu";

@import "@/components/stockchart/styles/stockchart_menu_secundario.scss";
@import "@/styles/basics/globals.scss";
.mdc-menu .mdc-menu-surface .stockchart-menu-secundario{
  z-index: 0;
}


#dragable {
  height: calc(100vh);
  background: #eaeaea;
  width: 1px;
  cursor: ew-resize;
  z-index: 99;
  position: absolute;

  #trigger {
    position: absolute;
    right: 0px;
    top: 373px;
    background: #333333;
    color: white;
    width: 48px;
    border-radius: 16px;
    text-align: center;
    box-shadow: 1px 1px 2px #1976d2;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:hover {
      box-shadow: 1px 1px 5px #1576d2;
    }
  }
}

li.eurekers-parent-menu-active {
      background: var(--color-custom-active-parent-menu);
      span.material-icons-outlined,
      span.material-label-ekers typo-caption {
          color: #00AEDD;
      }
}
#stockchart-menu-secundario{
  position: absolute;
  right: 0;
  overflow: hidden;
  height: 100%;
  border-left: 1px solid #d9dadb;
}

.material-label-ekers typo-caption{
  margin-bottom: 10%;
}
.mdc-list-item__text{
  margin-bottom: 25%;
}

.item-icon{
  margin-top: -20px;
}

.broker-connection-status {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 10px;
}
.broker-connection-status.connected {
  background: lime;
}
.broker-connection-status.not-connected {
  background: red;
}

#stockchart-menu-secundario {
  background-color: var(--color-background-surface);
}

#stockchart-menu-secundario .mdc-list-item span {
  color: var(--color-typography-on-background) !important;
}

#stockchart-menu-secundario .platinum-display span {
  color: black !important;
}

.platinum-display{
  display: inline-grid;
  text-align: center;
}

.plat-score-pc-btn {
  border: 0;
  background-color: white;
  cursor: not-allowed;
}

.plat-button-container {
  padding: 0;
  height: 80px;
}
.broker-connection{
  margin-bottom: 110px;
}

