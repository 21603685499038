






















































































































































































.ticker-name-container{
  background-color: #f3f4f7; 
  width: 250px;
  height: 40px;
  margin: auto;
  text-align: center;
}
.ticker-name-container p{
  padding-top: 10px;
}

.textarea-container{
  margin-top: 5px;
}

.text-note-container{
  display: inline-flex;
}

.btn-check-container{
  margin-top: 100px;
  margin-left: 10px;
}

.check-btn{
  background-color: #00aedd;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 20px;
  cursor: pointer;
}

.check-btn:hover{
  box-shadow: 1px 3px 8px black;
}

.textarea-style{
  width: 320px;
}

.input-style{
  width: 350px;
}

.add-notes-container{
  margin-top: 30px;
}

.remove-btn-container{
  margin-left: 320px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.div-notas-style{
  border: 1px solid #e0e0e0;
  width: 380px;
  padding-left: 20px;
  margin: auto;
  margin-top: 15px;
}

.date-text{
  float: right;
  margin-right: 20px;
}

.matter-text{
  font-weight: bold;
}

.remove-btn{
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.input-container-size.input-style{
  margin: auto;
}
#div-notas-id * {
  text-align: left;
}

#notas #ticker-name-container, #notas #ticker-name-container span {
  background-color: var(--custom-color-right-menu-highlight);
  color: var(--custom-color-right-menu-font);
}

#notas span, #notas #ticker-name-container, #text-note {
  color: var(--custom-color-right-menu-font) !important;
}
