





















































@import "@/styles/basics/globals.scss";

.content-container{
  margin-top: 30px;
}

.ticker-description{
  padding: 10px;
  margin-bottom: 40px;
}

.ticker-name{
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  background-color: #f3f4f7;
  margin: auto;
  width: 200px;
}

.ticker-subtitle{
  font-size: 14px;
  padding: 10px 10px 0px 10px;
}

.mobile-content {
  margin-top: 15px;
  height: 460px;
  min-height: 420px;
  overflow-y: scroll;
}

.header-container{
  display: flex;
  justify-content: center;
  padding: 10px 0px 5px 0px !important;
}

.title-container{
  margin-left: 30px;
  font-size: 20px;
  font-weight: 600;
}

.close-btn-container{
  margin-left: auto;
  order: 2;
  margin-right: 10px;
}

.info-ticker-box {
  width: 100%;
  display: flex;
  justify-content: center;
}

.text-info-ticker {
  text-align: center;
  background-color: #F3F4F7;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 24px;
  margin-top: 0px;
  width: 130px;
  padding: 7px 20px;
  color: black;
}

.text-bold {
  font-weight: bold;
}

.ticker-description-null{
  text-align: center;
  margin-top: 20px;
}

.not-allowed-description{
  text-align: center;
  margin-top: 20px;
  color: #FF9B00;
  font-weight: 500;
}

