$sidebar: 64px;
$open_sidebar: 315px;
$height_counter: 80px;
$height_order: 32px;
$eurekers_color: #00add8;
$main_color: #151929;
$main_color_white: #ffffff;
$main_background_content: red;
.app {
  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background-color: $eurekers_color;
    color: $main_color_white;
  }
  &__title {
    display: block;
    font-size: 1.5em;
  }
}

.stockchart-content {
  display: flex;
  .c-favoritos,
  .c-stockchart {
    display: flex;
    flex: 1 0;
    .stocks {
      flex: 0 auto;
    }
    .chart {
      flex: 1 0 auto;
    }
  }
}

.TVChartContainer {
  height: calc(100vh);
}

#my-list {
  overflow-y: auto;
  height: calc(100vh - 187px); // $height_counter + $height_order + $height_portfolio_selection
  padding: 0;
}

#my-list span {
  color: var(--color-typography-on-background);
}

#my-list .material-icons {
  color: var(--color-custom-typo-stocklist);
}

.sidebar-menu-margin {
    margin-left: 270px;
    margin-right: 52px;
}

.sidebar-menu-margin-stocks-table {
    margin-left: 80px;
    margin-right: 52px;
}

.main-sidebar-left {
    position: absolute;
    margin-left: 70px;
    width: 200px;
}

.stocks-info-query {
  height: $height_counter;
  background: $main_color;
  color: white;
  padding: 0;
  margin: 0;
  text-align: center;
  line-height: $height_counter;
  .counter {
    float: left;
    font-size: 24px;
    font-weight: 700;
    margin-left: 20px;
    width: 64px;
  }
  .tickers {
    float: left;
    margin-left: 10px;
    text-transform: uppercase
  }
}

.stocks-order-query {
  background: #d1d1d1;
  color: #444424;
  margin: 0;
  justify-content: space-around;
  display: flex;
  .icon {
    font-size: 20px;
    font-weight: 700;
    width: 40px;
  }
  .key {
    font-size: 14px;
    font-weight: 600;
  }
}

span.material-label-ekers {
  left: 0px;
  width: 100%;
  line-height: 18px;
  position: absolute;
  bottom: 10px;
  text-align: center;
  color: black;
  font-size: 13px;
  text-transform: capitalize;
}

.material-label-ekers-platinum-letter {
  font-size: 27px;
  font-weight: 700;
}

span.material-icons-outlined.md32, .alarm.push {
  color: #1e1e1e;
}

.alarm-selected {
  color: var(--color-custom-typo-mobile-buttons-stockchart) !important;
}