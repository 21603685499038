























































































































































































































































































































































































































































































@use "@material/list/mdc-list";
@use "@material/menu/mdc-menu";

@import "@/components/stockchart/styles/stockchart_menu.scss";

li.eurekers-parent-menu-active {
    background: var(--color-custom-active-parent-menu);
    span.material-icons-outlined,
    span.material-label-ekers typo-caption,
    span.material-label-ekers typo-caption-platinum-letter {
        color: #00AEDD;
    }
}

a { text-decoration: none }

.content-link-favorites-link a {
    margin: 4px;
}

.content-link-favorites-link {
    position: absolute;
    width:230px;
    background-color: #f3f4f7;
    border-left-style: none;
    box-shadow: 0px 2px 3px #A3A3A3;
    padding: 10px;
    border: 1px solid #b9b9b9;
    border-radius:12px;
    margin-left: 80px;
}
hr{  
    border: none;
    border-left: 1px solid hsla(200, 10%, 50%,100);
    height: 2vh;
    width: 1px; 
    margin:none;   
    margin: 0em;
    margin-top:5px;
    border-width: 1.5px;   
}
.favorite-text{
  font-size: 14px;
  margin-right: 20px;
  font-weight: 600;
}
.favorite-stars-container-stockchart-menu{
  float: left;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
  justify-content: space-between;
  .icons-container{
    display:none;
  }
}

.favorite-stars-container-stockchart-menu:hover{
  float: left;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
  justify-content: space-between;
  .icons-container{
    display:block;
  }
}

.anonymous :hover{
  cursor: not-allowed;
}

.favorites-close-icon-container{
  float: right;
}

.favorite-title-container{
  margin-left: 70px;
  float: left;
}

.star-style{
  cursor:pointer;
}
.workspace-name-style:hover{
  color: #0E5F85; 
  cursor:pointer;
}
.workspace-name-style{
  margin-left: 10px;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's on top */
}

.modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(15, 15, 26, 0.7); /* --color-background with alpha */
  z-index: 1001; /* Backdrop behind modal content */
}

/* Ensure the modal content itself is above the backdrop */
.modal-container > :not(.modal-backdrop) {
  z-index: 1002; 
}

.not-allowed-functionality{
  cursor: not-allowed;
  justify-content: center;
  width: 80px;
  padding: 14px 0;
  margin: 0;
  height: 48px;
  display: flex;
  position: relative;
  align-items: center;
}

.not-allowed-searches-stocks,
.not-allowed-favorites-stocks,
.not-allowed-finder-stocks{
  cursor: not-allowed;
}

