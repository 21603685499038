


















































































































































































































































































































































.searches-modal-content {
    background-color: var(--color-background, white);
    border-radius: 8px;
    width: 400px;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    color: var(--color-text-primary, #0F0F1A);
    box-shadow: 0 4px 20px rgba(15, 15, 26, 0.15);
}

.modal-header {
    padding: 16px 20px;
    border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
}

.title-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.header-icon {
    color: var(--primary, #00AEDD);
    font-size: 22px;
}

.modal-title {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}

.modal-close-btn {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--color-text-secondary, #606577);
    padding: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    transition: background-color 0.2s ease-in-out;
}

.modal-close-btn:hover {
    background-color: var(--color-surface, #F3F4F7);
}

.modal-close-btn i {
    font-size: 20px;
}

.modal-body {
    padding: 15px 20px;
    overflow-y: auto;
    flex-grow: 1;
}

.modal-body::-webkit-scrollbar {
    width: 8px;
}

.modal-body::-webkit-scrollbar-track {
    background: var(--color-surface, #F3F4F7);
    border-radius: 4px;
}

.modal-body::-webkit-scrollbar-thumb {
    background-color: var(--color-text-secondary, #B0B4C3);
    border-radius: 4px;
    border: 2px solid var(--color-surface, #F3F4F7);
}

.section-title {
    font-size: 13px;
    font-weight: 600;
    color: var(--color-highlight, #00AEDD);
    text-transform: uppercase;
    margin: 0 0 10px 0;
    letter-spacing: 0.5px;
}

.list-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.list-item:hover {
    background-color: var(--color-surface, #F3F4F7);
    color: var(--primary, #00AEDD);
}

.item-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.item-text {
    font-size: 14px;
    font-weight: 500;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
}

.link-text {
    color: var(--color-text-primary, #0F0F1A);
    text-decoration: none;
}

.list-item:hover .link-text,
.list-item:hover .personal-search-name {
     color: var(--primary, #00AEDD);
}

.item-icon-right {
    font-size: 20px;
    color: var(--color-text-secondary, #606577);
}

.list-item:hover .item-icon-right {
    color: var(--primary, #00AEDD);
}

.personal-search-name {
    color: var(--color-text-primary, #0F0F1A);
}

.item-actions {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
}

.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-text-secondary, #606577);
    transition: color 0.2s ease, background-color 0.2s ease;
}

.icon-button:hover {
    background-color: var(--color-muted, #EAECEF); 
}

.icon-button i {
    font-size: 18px;
}

.delete-icon:hover {
    color: var(--color-error, #B00020);
}

.edit-icon:hover {
    color: var(--primary, #00AEDD);
}

.confirm-edit-btn:hover {
    color: var(--color-success, #57a869);
}

.cancel-edit-btn:hover {
    color: var(--color-error, #B00020); 
}


.edit-input-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}

.edit-input-wrapper ::v-deep .input-container {
    margin-bottom: 0 !important;
}

.edit-input-wrapper ::v-deep .text-field-input {
    height: 36px;
    font-size: 14px;
}

hr {
    border: none;
    height: 1px;
    background-color: var(--color-outline, rgba(0, 0, 0, 0.12));
    margin: 15px 0;
}

.no-items-message {
    text-align: center;
    padding: 20px;
    color: var(--color-text-secondary, #606577);
    font-style: italic;
}

.delete-confirm-view {
    padding: 10px 0;
    text-align: center;
}

.delete-question-text {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
}

.search-to-delete-info {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    background-color: var(--color-surface, #F3F4F7);
    padding: 8px 15px;
    border-radius: 6px;
    margin-bottom: 15px;
    color: var(--color-text-primary, #0F0F1A);
}

.search-name-delete {
    font-weight: 600;
}

.delete-warning {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: var(--color-warning, #FF9B00);
    font-size: 14px;
    margin-top: 10px;
}

.warning-icon {
    font-size: 20px;
}

.modal-footer {
    padding: 16px 20px;
    border-top: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    flex-shrink: 0;
}

.action-btn {
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    padding: 0 16px;
    height: 36px;
    transition: all 0.2s ease;
    font-weight: 600;
}

.secondary-btn {
    background-color: var(--color-surface, #F3F4F7);
    color: var(--color-text-primary, #0F0F1A);
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
}

.secondary-btn:hover {
    background-color: var(--color-background, #EAECEF);
}

.delete-btn {
    background-color: var(--color-error, #B00020);
    color: white;
}

.delete-btn:hover {
    background-color: #9a001b;
}

// Style for the vanilla input used in editing
.vanilla-input {
    flex-grow: 1;
    margin-right: 10px;
    height: 36px; 
    padding: 6px 12px;
    font-size: 14px;
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    border-radius: 4px;
    background-color: var(--color-background, white);
    color: var(--color-text-primary, #0F0F1A);
    transition: border-color 0.2s ease;
    box-sizing: border-box;
}

.vanilla-input:focus {
    outline: none;
    border-color: var(--primary, #00AEDD);
    box-shadow: 0 0 0 2px rgba(0, 174, 221, 0.2);
}

.vanilla-input::placeholder {
    color: var(--color-text-secondary, #606577);
    opacity: 0.6;
}
