























































































































































































@use "@material/list";

@include list.core-styles;
@include list.item-selected-text-color(black);

.mdc-list-item {
  @include list.item-primary-text-ink-color(#444);
  @include list.list-item-height(36px);
  border-bottom: 1px solid #e9e9e9;
}

:not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple[data-v-1d7efbc2]::before {
    background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, red));
}

li.mdc-list-item {display:flex;}
li span.mdc-list-item__meta.material-icons {margin:unset; margin-left: 4px}
li span.mdc-list-item__meta.material-icons.push {margin-left: auto;}

.mdc-list-item.mdc-list-item--selected::before {
  display:none;
}

li.mdc-list-item--selected .material-icons {
  &.alarm:hover {color:green}
  &.notifications:hover {color:orange;}
  &.favorite:hover {color:#00add8;}
  &.folder:hover{color: #89430C;}
}

li.mdc-list-item .material-icons {
  &.alarm.active {color:green}
  &.notifications.active {color:orange;}
  &.favorite.active {color:#00add8;}
  &.folder.active{color: #89430C;}
}

.favorite, .alarm {
  font-size: 22px;
}

.ekers-event-click {
  cursor:pointer;
}
.stocks-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  background-color: #f3f4f7;
  /*background-color: var(--color-background-background);*/
}

.stocks-order-query{
  height: 30px;
  background: var(--color-background-outline);
  border: 1px solid rgba(15, 15, 26, 0.2);
}

.stocks-order-query:hover{
  cursor: pointer;

}

.order-text{
  margin: 10px 0 10px 7px;
}
.div-text-order:hover{
  background-color: #e5e5e5;
}
.div-text-order{
  padding: 10px 0 10px 7px;
}

.order-menu{
  position: absolute; 
  background: #FBFBFB;
  border: 1px solid #E0E1E6;
  width: 95.7%; 
  opacity: 1; 
  z-index:1; 
  box-shadow: 0 2px 8px rgb(0 0 0 / 33%);

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  letter-spacing: 0.4px;

  color: var(--color-primary-900);
}

.blured{
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  background-color: white;
}

.mdc-list-item__text{
  color: var(--color-primary-secondary);
}

.stocks-info-query{
  background-color: var(--color-primary-900);
}
.actions-container{
    display: flex;
    //justify-content: space-between;
    //background-color: var(--color-background-surface);
    height: 100%;
    color: var(--color-custom-typo-stocklist);
}

.add-to-portfolio{
    margin-left:20%; 
    margin-top: 7px;
}

.add-to-favorites{
    margin-right:20%; 
    margin-top: 7px;
}

.not-allowed-actions-mobile-container{
  height: 100%;
  width: 100%;
  background-color: #f3f4f7;
  color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
