


























































































































































































































































































































































































































































@import "@/styles/basics/globals.scss"; // Make sure this path is correct

#registro {
  width: 100%;
  display: flex;
  flex-direction: column; // Ensure header is above content
}

.registry-container {
  max-width: 600px;
  margin: 0 auto 40px auto; // No top margin, let Header handle spacing
  padding: 20px;
  border-radius: 10px;
  background-color: var(--color-background);
  box-shadow: 0 2px 10px rgba(15, 15, 26, 0.08);
  width: 85%;
}

// Common Ticker Info Box Style (from other components)
.info-ticker-box {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.text-info-ticker {
  text-align: center;
  background-color: var(--color-surface, #F3F4F7);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding: 8px 30px;
  border-radius: 20px;
  color: var(--color-text-primary);
  font-weight: 500;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.text-bold {
  font-weight: 700;
  color: var(--primary, #00aedd);
}

// Filter Buttons
.filter-buttons-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 25px;
  flex-wrap: wrap;
}

.filter-btn {
  padding: 8px 15px;
  border-radius: 20px;
  border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
  background-color: var(--color-surface, #F3F4F7);
  color: var(--color-text-secondary, #606577);
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 13px;
  font-weight: 500;

  &:hover {
    background-color: var(--color-background, #EAECEF);
    border-color: var(--color-text-secondary, #606577);
  }

  &.active {
    background-color: var(--primary, #00aedd);
    color: white;
    border-color: var(--primary, #00aedd);
    font-weight: 600;
  }
  
  // Assign specific active colors if needed
  // &.active[data-type="favorite"] { background-color: #E39B0F; border-color: #E39B0F; }
  // &.active[data-type="portfolio"] { background-color: #BA91BB; border-color: #BA91BB; }
  // &.active[data-type="note"] { background-color: #494C5A; border-color: #20202B; } // Adjusted note color
  // &.active[data-type="alarm"] { background-color: #1F7AA0; border-color: #1F7AA0; } 
}

// Entries List
.entries-list-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  // max-height: calc(100vh - 350px); // Adjust as needed for scroll height
  // overflow-y: auto; // Add scroll if needed
  margin-bottom: 25px;
}

.registry-item {
  border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
  border-radius: 5px;
  padding: 10px 0; // Padding top/bottom
  border-left-width: 5px;
  border-left-style: solid;
}

// Define border colors per type
.registry-item-favorite { border-left-color: #E39B0F; }
.registry-item-portfolio { border-left-color: #BA91BB; }
.registry-item-note { border-left-color: #494C5A; } // Darker grey/blue
.registry-item-alarm { border-left-color: #1F7AA0; } // Consistent alarm blue

.registry-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 5px 15px; // Padding left/right/bottom
}

.registry-date {
  font-size: 14px;
  font-weight: 600;
  color: var(--color-text-primary, #0F0F1A);
  margin: 0;
}

.icon-button { // Copied from Alarmas.vue
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-button i { // Copied from Alarmas.vue
  font-size: 18px;
  color: var(--color-text-secondary, #606577);
  transition: color 0.2s ease;
}

.delete-button:hover i { // Copied from Alarmas.vue
  color: var(--color-error, #B00020);
}

.registry-text {
  font-size: 13px;
  line-height: 1.5;
  color: var(--color-text-primary, #0F0F1A);
  padding: 0 15px 5px 15px; // Padding left/right/bottom
  margin: 0;
  word-break: break-word;
}

.no-entries-message {
  text-align: center;
  padding: 20px 0;
  color: var(--color-text-secondary, #606577);
  font-style: italic;
}

// No Access Message
.no-access-container {
    text-align: center;
    padding: 30px 0;
    color: var(--color-text-secondary, #606577);
    font-style: italic;
}


// Note Input Area
.note-input-container {
  margin-top: 20px;
  border-top: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.note-textarea {
  width: 100%; 
  min-height: 80px;
  padding: 10px 12px;
  border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
  border-radius: 6px;
  font-family: inherit;
  font-size: 14px;
  color: var(--color-text-primary);
  background-color: var(--color-surface, #F3F4F7);
  resize: vertical;
  box-sizing: border-box; // Ensure padding is included in width

   &:focus {
      outline: none;
      border-color: var(--primary, #00aedd);
      box-shadow: 0 0 0 2px rgba(0, 174, 221, 0.1);
      background-color: var(--color-background, white);
  }
  
  &::placeholder {
      color: var(--color-text-secondary, #606577);
      opacity: 0.7;
  }
}

.save-note-btn {
  display: inline-flex; // To align icon and text
  align-items: center;
  justify-content: center;
  gap: 8px;
  align-self: flex-end; // Position button to the right
  min-width: 150px; // Give it some default width
}

.save-note-btn i {
    font-size: 18px; // Adjust icon size
}

// General Action Button Styles (copied from Error.vue/Alarmas.vue)
.action-btn {
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  padding: 0 16px;
  height: 40px;
  transition: all 0.2s ease;
  font-weight: 600;
}

.primary-btn {
  background-color: var(--primary, #00aedd);
  color: white;
  box-shadow: none;

  &:hover:not(:disabled) {
    background-color: var(--color-muted, #35add8); // Use muted color from globals
    box-shadow: none;
  }

  &:disabled {
    background-color: var(--color-muted, #e9ecf1); // Use muted color from globals
    color: var(--color-text-secondary, #606577);
    cursor: not-allowed;
  }
}

/* Mobile responsiveness */
@media (max-width: 767px) {
  .registry-container {
    width: 92%;
    padding: 15px;
  }

  .filter-buttons-container {
      gap: 8px;
  }
  
  .filter-btn {
      padding: 6px 12px;
      font-size: 12px;
  }
  
  .entries-list-container {
      // Adjust max-height for smaller screens if needed
  }
  
  .save-note-btn {
      width: 100%; // Make button full width on mobile
      align-self: center; // Center button on mobile
  }
}

