










































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.simulator-container {
  max-width: 600px;
  margin: 20px auto;
  margin-bottom: 20px;
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--color-background);
  box-shadow: 0 2px 10px rgba(15, 15, 26, 0.08);
  width: 85%;
}

.table-wrapper {
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 1px 2px rgba(15, 15, 26, 0.1);
  border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

.table-header-row {
  background-color: var(--color-surface, #F3F4F7);
  border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
}

.table-header-cell {
  color: var(--color-text-secondary, rgba(0, 0, 0, 0.6));
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 12px 16px;
  font-weight: 600;
}

.table-row {
  background-color: var(--color-background, white);
}

.table-cell {
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
}

.table-cell:last-child {
  border-right: none;
}

.positive-value {
  color: var(--color-success, #57a869);
}

.negative-value {
  color: var(--color-error, #B00020);
}

.simulator-highchart {
  margin: 20px 0;
  border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
  border-radius: 6px;
  overflow: visible;
  min-height: 330px;
  background-color: var(--color-surface, #F3F4F7);
  position: relative;
}

#highchart-content {
  height: 330px;
  width: 100% !important;
  min-height: 330px;
  padding: 15px;
  display: block;
  position: relative;
  overflow: visible;
}

/* Fix for Highcharts responsive layout */
:deep(.highcharts-container),
:deep(.highcharts-root) {
  width: 100% !important;
  height: 100% !important;
  overflow: visible !important;
}

:deep(.highcharts-series-group) {
  overflow: visible !important;
}

:deep(.highcharts-point) {
  stroke-width: 0;
}

:deep(.highcharts-grid-line) {
  stroke-dasharray: 2, 2;
}

:deep(.highcharts-plot-border) {
  stroke-width: 0;
}

.info-panel {
  background-color: var(--color-surface, #f5f7fa);
  border-radius: 6px;
  padding: 15px;
  margin: 20px 0;
  border-left: 4px solid var(--primary, #00aedd);
}

.info-text-style {
  margin: 0;
  font-size: 13px;
  line-height: 1.5;
  color: var(--color-text-primary, #0F0F1A);
}

.ticker-info-container {
  background-color: var(--color-surface, #f5f7fa);
  border-radius: 6px;
  padding: 15px 20px;
  margin: 15px 0;
}

.ticker-info-container p {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: var(--color-text-primary, #0F0F1A);
}

.ticker-info-container span {
  color: var(--color-text-secondary, #606577);
}

.simulator-section-title {
  margin: 25px 0 15px;
  text-align: left;
}

.simulator-section-title p {
  font-size: 14px;
  font-weight: 600;
  color: var(--color-text-primary, #0F0F1A);
  margin: 0;
  letter-spacing: 0.1px;
}

.simulator-chips-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 15px 0;
}

.simulator-chips-box {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
  color: var(--color-text-primary, #0F0F1A);
  border-radius: 16px;
  padding: 6px 12px;
  transition: all 0.2s ease;
}

.simulator-chips-box.active-index {
  background-color: var(--color-highlight, #000000);
  color: white;
}

.ticker-label, .index-name {
  cursor: pointer;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-button i {
  font-size: 18px;
  color: var(--color-text-secondary, #606577);
  transition: color 0.2s ease;
}

.delete-button:hover i {
  color: var(--error, #B00020);
}

.confirm-button i {
  font-size: 18px;
  color: var(--success, #57a869);
}

.separator {
  border: none;
  border-top: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
  margin: 25px 0;
}

.simulator-input-section {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.add-ticker-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 200px;
}

.add-ticker-btn i {
  font-size: 20px;
}

/* Ticker search modal styles */
.ticker-search-modal {
  max-width: 600px;
  width: 95%;
}

.ticker-search-form {
  width: 100%;
}

.autocomplete-wrapper {
  margin-bottom: 12px;
  position: relative;
  width: 100%;
}

.search-helper-text {
  font-size: 12px;
  color: var(--color-text-secondary, #606577);
  margin-top: 6px;
  text-align: center;
  font-style: italic;
}

.autocomplete-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  width: 100%;
}

.autocomplete-item-content {
  flex: 1;
  min-width: 0;
  overflow: hidden;
}

.autocomplete-item-action {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-highlight, #00aedd);
  margin-left: 10px;
  flex-shrink: 0;
}

.autocomplete-item-action i {
  font-size: 20px;
}

.autocomplete-item-title {
  font-weight: 600;
  font-size: 16px;
  color: var(--color-text-primary, #0F0F1A);
  margin: 0 0 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.autocomplete-item-description {
  font-size: 13px;
  color: var(--color-text-secondary, #606577);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.not-available-data-container {
  text-align: center;
  max-width: 700px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  background-color: var(--color-background, white);
  box-shadow: 0 1px 3px rgba(15, 15, 26, 0.1);
}

.info-ticker-box {
  width: 100%;
  display: flex;
  justify-content: center;
}

.text-info-ticker {
  text-align: center;
  background-color: #F3F4F7;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 16px;
  padding: 7px 25px;
  border-radius: 4px;
}

.text-info-ticker span {
  font-size: 18px;
}

.text-bold {
  font-weight: bold;
}

.not-available-data {
  color: #FF9B00;
  margin: 20px 0;
}

.link-to-store {
  color: #35add8;
  text-decoration: none;
  font-weight: 500;
}

.link-to-store:hover {
  text-decoration: underline;
}

.load-save-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.index-actions-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 20px 0;
  flex-wrap: wrap;
}

.index-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 220px;
}

.index-btn i {
  font-size: 20px;
}

.secondary-btn {
  background-color: var(--color-surface, #F3F4F7);
  color: var(--color-text-primary, #0F0F1A);
  border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
}

.secondary-btn:hover {
  background-color: var(--color-background, #EAECEF);
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(15, 15, 26, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: var(--color-background, #FFFFFF);
  border-radius: 8px;
  width: 95%;
  max-width: 500px;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 20px rgba(15, 15, 26, 0.15);
}

.modal-header {
  padding: 16px 20px;
  border-bottom: 1px solid var(--color-outline, rgba(15, 15, 26, 0.2));
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: var(--color-text-primary, #0F0F1A);
}

.modal-close-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-text-secondary, #606577);
}

.modal-body {
  padding: 20px;
  overflow-y: auto;
  max-height: 60vh;
  box-sizing: border-box;
  position: relative;
}

.modal-footer {
  padding: 16px 20px;
  border-top: 1px solid var(--color-outline, rgba(15, 15, 26, 0.2));
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.modal-indices-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal-index-item {
  padding: 12px 16px;
  border-radius: 6px;
  border: 1px solid var(--color-outline, rgba(15, 15, 26, 0.2));
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.modal-index-item:hover {
  background-color: var(--color-surface, #F3F4F7);
}

.modal-index-item.selected-index {
  background-color: var(--color-active-surface, #d9ecf3);
  border-color: var(--color-highlight, #00aedd);
}

.modal-index-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.modal-index-name {
  font-weight: 600;
  font-size: 16px;
  color: var(--color-text-primary, #0F0F1A);
}

.modal-index-tickers {
  font-size: 13px;
  color: var(--color-text-secondary, #606577);
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-index-actions {
  display: flex;
  gap: 8px;
}

.delete-index-btn i {
  color: var(--color-text-secondary, #606577);
  transition: color 0.2s ease;
}

.delete-index-btn:hover i {
  color: var(--color-error, #B00020);
}

.edit-index-btn i {
  color: var(--color-text-secondary, #606577);
  transition: color 0.2s ease;
}

.edit-index-btn:hover i {
  color: var(--color-highlight, #00aedd);
}

.no-indices-message {
  text-align: center;
  padding: 30px 0;
  color: var(--color-text-secondary, #606577);
  font-style: italic;
}

@media (max-width: 767px) {
  .modal-container {
    width: 95%;
    max-height: 80vh;
  }
  
  .modal-index-tickers {
    max-width: 200px;
  }
}

/* Delete confirmation modal styles */
.delete-confirm-modal {
  max-width: 450px;
}

.delete-confirm-message {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 16px;
  color: var(--color-text-primary, #0F0F1A);
}

.delete-warning {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--color-warning, #FF9B00);
  font-size: 14px;
}

.warning-icon {
  font-size: 20px;
}

.delete-btn {
  background-color: var(--color-error, #B00020);
  color: white;
}

.delete-btn:hover {
  background-color: #d32f2f;
}

/* Save Index Modal Styles */
.save-index-modal {
  max-width: 450px;
}

.form-group {
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
}

.input-label {
  display: block;
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-text-primary, #0F0F1A);
}

.vanilla-input {
  width: 100%;
  height: 40px;
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid var(--color-outline, rgba(15, 15, 26, 0.2));
  border-radius: 4px;
  background-color: var(--color-background, #FFFFFF);
  color: var(--color-text-primary, #0F0F1A);
  transition: border-color 0.2s ease;
  box-sizing: border-box;
}

.vanilla-input:focus {
  outline: none;
  border-color: var(--color-highlight, #00aedd);
  box-shadow: 0 0 0 2px rgba(0, 174, 221, 0.2);
}

.vanilla-input::placeholder {
  color: var(--color-text-secondary, #606577);
  opacity: 0.6;
}

.input-helper {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  color: var(--color-text-secondary, #606577);
  margin-top: 4px;
}

.index-tickers-summary {
  background-color: var(--color-surface, #F3F4F7);
  border-radius: 6px;
  padding: 12px 16px;
}

.tickers-label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 6px;
  color: var(--color-text-primary, #0F0F1A);
}

.tickers-list {
  font-size: 13px;
  line-height: 1.5;
  color: var(--color-text-secondary, #606577);
  word-break: break-all;
}

.no-tickers-message {
  background-color: var(--color-surface, #F3F4F7);
  border-radius: 6px;
  padding: 15px;
  text-align: center;
  color: var(--color-text-secondary, #606577);
}

.tickers-helper {
  font-size: 13px;
  line-height: 1.4;
  margin-top: 8px;
  font-style: italic;
}

.save-index-form {
  width: 100%;
  box-sizing: border-box;
}

/* Selected index styles */
.selected-index-container {
  background-color: var(--color-active-surface, #d9ecf3);
  border: 1px solid var(--color-highlight, #00aedd);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
}

.selected-index-header {
  margin-bottom: 15px;
  text-align: center;
}

.selected-index-title {
  font-size: 16px;
  font-weight: 600;
  color: var(--color-text-primary, #0F0F1A);
  margin: 0;
}

.selected-index-title span {
  color: var(--color-highlight, #00aedd);
}

.changes-status {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin: 10px 0 0;
  font-size: 13px;
  color: var(--color-highlight, #00aedd);
}

.changes-status i {
  font-size: 16px;
}

.selected-index-content {
  margin-bottom: 15px;
}

.selected-tickers-summary {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 6px;
  padding: 10px 15px;
  margin-bottom: 15px;
}

.selected-tickers-label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  color: var(--color-text-primary, #0F0F1A);
}

.selected-tickers-list {
  font-size: 13px;
  line-height: 1.5;
  color: var(--color-text-secondary, #606577);
  word-break: break-all;
  margin: 0 0 5px;
}

.changes-indicator {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  margin: 8px 0 0;
  color: var(--color-highlight, #00aedd);
}

.changes-indicator i {
  font-size: 16px;
}

.selected-index-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.equal-width-btn {
  min-width: 180px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.equal-width-btn i {
  font-size: 18px;
  display: flex;
  align-items: center;
}

.equal-width-btn span {
  line-height: 1;
}

.success-btn {
  background-color: var(--color-success, #57a869);
  color: white;
}

.success-btn:hover {
  background-color: #4c9a5d;
}

.warning-btn {
  background-color: var(--color-warning, #FF9B00);
  color: white;
}

.warning-btn:hover {
  background-color: #e08c00;
}

.tertiary-btn {
  background-color: var(--color-background, white);
  color: var(--color-text-primary, #0F0F1A);
  border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
}

.tertiary-btn:hover {
  background-color: var(--color-surface, #F3F4F7);
}

/* Mobile responsiveness for selected index */
@media (max-width: 767px) {
  .selected-index-container {
    padding: 12px;
  }
  
  .selected-index-actions {
    flex-direction: column;
  }
  
  .selected-index-actions .action-btn {
    width: 100%;
  }
  
  .equal-width-btn {
    min-width: 100%;
    margin-bottom: 5px;
  }
}

.action-btn {
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  padding: 0 16px;
  height: 36px;
  transition: all 0.2s ease;
  font-weight: 600;
}

.primary-btn {
  background-color: var(--color-highlight, #00aedd);
  color: white;
}

.primary-btn:hover {
  background-color: var(--color-muted, #35add8);
}

.simulator-button-add {
  min-width: 100px;
  margin-left: 10px;
  border-radius: 4px;
  height: 32px;
}

.added-btn {
  background-color: var(--color-surface, #F3F4F7);
  color: var(--color-text-secondary, #606577);
  border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
  cursor: default;
}

/* Mobile responsiveness */
@media (max-width: 767px) {
  .simulator-container {
    padding: 15px;
  }
  
  .simulator-table {
    margin: 10px 0;
  }
  
  .ticker-info-container p {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .simulator-button-add {
    margin: 10px 0 0;
    width: 100%;
  }
  
  .add-ticker-btn {
    width: 100%;
  }
}
