






































































































































































































































































































































    .separator{
        margin-left:59px;
        margin-top:26px;
    }
    @media (max-width: 900px){
        .stocks-finder{
            z-index: 2;
            left: 0px;
            background: #f3f4f7;
            padding-top: 20px;
            border-left: 1px solid #dddddd;
            width: 100%;
            height:45%;           
            overflow-y:scroll;
        }
        .cripto-text{
            
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */
        margin-left:10px;
        letter-spacing: 0.1px;

        /* 02. Secondary/400 */

        color: #626777;
        }
        .filter-tittle{
            position: none !important;
            font-weight: 600;
        }
        .inline-block {
            display: inline-block;
            width: 124px;
            height: 24px;
            right: 139px;
            top: 50px;
        }
        .crypto-toggle div{
            display: inline-block;
        }
    }
    @media (min-width: 901px) {
        .stocks-finder {
            position: absolute;
            z-index: 3;
            top: 0px;
            left: 13.3vw;
            background: #ebebeb;
            padding: 20px;
            border-left: 1px solid #dddddd;
            width: calc(77vw);
            height: calc(88vh);
            overflow:scroll;
        }
        .inline-block {
            display: inline-block;
            position: absolute;
            width: 124px;
            height: 24px;
            right: 139px;
            top: 50px;
        }
        hr {
            margin: 20px 0 !important;
            width: 100% !important;
        }

        #filters-user {
            padding-right: 59px !important;
        }
    }        

    h4 {
        line-height: 3px;
    }
    
    @media screen and(min-width: 901px){
        #stocks-finder{
            z-index: 99;
            background-color: #f3f4f7;
            position: fixed;
            left: 332px;
            right: 80px;
            top: 77px;
            bottom: 0px;
            width: unset;
            //box-shadow: -4px 4px 6px rgba(0, 0, 0, 0.2);
            border: 0.5px solid rgba(0, 0, 0, 0.12);
        }

        .filter-tittle {
            position: absolute;
            width: 62px;
            height: 24px;
            left: 50px;
            top: 19px;
            margin-left:63px;
            margin-top:26px;
    
            /* Headline 6 */
    
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            /* identical to box height, or 120% */
    
            letter-spacing: 0.15px;
    
            /* 02. Secondary/900 */
    
            color: #000010;
    
        }
        .cripto-text {        

        /* Subtitle 2 */

        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        letter-spacing: 0.1px;
        margin-left:10px;

        /* 02. Secondary/400 */

        color: #626777;
    }
    }
    .material-icons {
        font-size: 24px;
        cursor: pointer;
        margin:none;
    }
    .reset-button{
        border-radius: 50px !important;
        color:white !important;
        background-color: #00aedd;
        border:none !important;
        height:25px !important;
        cursor: pointer !important;
        font-weight: 400 !important;
        letter-spacing: 1px !important;
        font-size: 12px !important;
        margin-bottom: 25px !important;
        padding: 3px 30px !important;
        box-sizing: content-box !important;
        margin: 0px !important;
        margin-top: 20px !important;
        margin-right: 10px !important;
    }
    /*.cripto-text{
        margin-top:47px;
        margin-bottom: 25px;

    }*/

    .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.cripto-container{
    margin-top:30px;
}

.save-button-container{
    text-align: center; 
}
.save-search-btn{
    background-color: #00aedd;
    color: white;
    border-radius: 15px;
    font-size: 11px;
    font-weight: bold;
    border: none;
    width: 190px;
    height: 30px;
    margin-top: 10px;
    cursor:pointer;
}
.add-search{
    margin-bottom: 20px;
    display:flex;
    justify-content: center;
    align-items: center;
}
.input-search-value{
    margin-left: 15px;
    margin-top: 10px;
    width:200px; 
}
.input-accept-button{
    width:83px;
    height:32px;
    color: white;
    background-color:#00aedd;
    border:none;    
    border-radius: 25px;
    margin-left: 15px;
    font-weight: bold;
    cursor:pointer;
    margin-bottom: 10px;
}
.reset-button-c * {
    margin: 0;
}

.save-button-c * {
    margin: 0;
}
.sencond-container-c {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}
.second-container-c .reset-button {
    margin-left: 0 !important;
}
.second-container-c div {
    margin: 5px 0;
    width: 200px;
}

.second-container-c button {
    width: 100%;
}

.add-search-c {
    margin-left: 40px !important;
}

.sencond-container {
    display: flex;
    justify-content: space-between;
    margin-right: 100px;
}

#stocks-finder{
    hr{
        margin-top: 10px;
    }
}

#mobile-filters-results-span {
    font-size: 14px;
}

.save-button-c .reset-button {
    background-color: #494c5a !important;
}
