




































































































































































































































































































































































































































































































































































.workspace-favorites-fullscreen-dark{
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 1001;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5) !important;
}

.workspace-name-container{
    text-align:center; 
    margin-top:15px;
}

.workspace-container-style{
    position: absolute;
    min-height: 400px;
    width: 110%;
    background: #F3F4F7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    top: 140px;
    left: 10%;
}

@media screen and(max-width: 350px){
    .workspace-container-style{
        width: 80%
    }

}

@media screen and(min-width: 351px) and(max-width: 391px){
    .workspace-container-style{
        width: 110%;
        left: 10%;
    }

}

@media screen and(min-width: 392px) and(max-width: 500px){
    .workspace-container-style{
        width: 120%;
        left: 5%;
    }

}

.edit-workspace-favorite{
    text-align: center;
    margin-top: 20px;
}

.edit-star-style{
    margin-left: 10px;
    margin-top: 15px;
}

.user-workspaces {
    width: 300px;
    z-index: 999999;
    display: block !important;
    color: black;
    text-align: left;
    position: absolute;
    text-decoration: none;
    background-color: #f3f4f7;
    border-radius: 5px;       
}

.title-favorites{
    margin-top: 10px; 
    margin-left: 20px;
    font-size: 15px;
}

.add-button-container{
    text-align: center;
    position:relative;
}

.close {
    position: absolute;
    right: 4px;
    cursor:pointer;
}

#workspace-select-favorite-icon {
    display: inline-block;
}
.remove-text-container{
    text-align:center;
    font-size:13px;
    margin-top:50px;
}
.remove-star-container{
    text-align:center;
    margin-top:25px;
    margin-bottom:25px;
}
.remove-button-container input{
    width:140px;
    height:35px;
    color:white;
    font-weight:bold;
    background-color:#00aedd;
    border-radius:30px;
    border-color:transparent;
   
}
.cancel-button-container input{
    border:none;
    color:#2e81a5;
    font-weight:bold;
    margin-top:15px;

}

.remove-star-containter{
    text-align: center;
    margin-bottom: 20px;
}
