


































































































































































@import "@/styles/basics/globals.scss";

#stock-error {
  width: 100%;
}

.error-report-container {
  max-width: 600px;
  margin: 20px auto;
  margin-bottom: 20px;
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--color-background);
  box-shadow: 0 2px 10px rgba(15, 15, 26, 0.08);
  width: 85%;
}

.info-ticker-box {
   width: 100%;
   display: flex;
   justify-content: center;
}

.text-info-ticker {
  text-align: center;
  background-color: var(--color-surface, #F3F4F7);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding: 8px 30px;
  border-radius: 20px;
  color: var(--color-text-primary);
  font-weight: 500;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.text-bold {
  font-weight: 700;
  color: var(--primary, #00aedd);
}

.error-selection-container {
  margin-top: 10px;
  text-align: center;
}

.error-selection-container p {
  margin-bottom: 12px;
  font-size: 15px;
  color: var(--color-text-primary);
}

.select-wrapper {
  position: relative;
  width: 100%;
  max-width: 350px;
  margin: auto;
  margin-top: 5px;
}

.styled-select {
  width: 100%;
  height: 40px;
  padding: 10px 15px;
  padding-right: 30px;
  font-size: 16px;
  color: var(--color-text-primary);
  background-color: var(--color-background);
  border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
  border-radius: 4px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  outline: none;
  transition: border-color 0.3s;
}

.styled-select:focus {
  border-color: var(--primary, #00aedd);
  box-shadow: 0 0 0 2px rgba(0, 174, 221, 0.1);
}

.select-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
  margin: auto;
  width: 0;
  height: 15px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid var(--color-text-secondary, #606577);
  pointer-events: none;
  display: block;
}

.helper-text {
  text-align: left;
  margin-left: 10px;
  margin-top: 3px;
  font-size: 12px;
  color: var(--color-text-secondary, #606577);
}

.error-description-container {
  margin: 25px 0;
}

.error-textarea {
  width: calc(100% - 20px);
  min-height: 80px;
  padding: 10px;
  border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
  border-radius: 6px;
  font-family: inherit;
  font-size: 14px;
  color: var(--color-text-primary);
  background-color: var(--color-surface, #F3F4F7);
  resize: vertical;
}

.btns-container {
  margin: 25px 0;
  text-align: center;
}

.action-btn {
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  padding: 0 16px;
  height: 40px;
  transition: all 0.2s ease;
  min-width: 200px;
  font-weight: 600;
}

.primary-btn {
  background-color: var(--primary, #00aedd);
  color: white;
  box-shadow: none;
}

.primary-btn:hover {
  background-color: var(--color-muted, #35add8);
  box-shadow: none;
}

.primary-btn:disabled {
  background-color: var(--color-muted, #e9ecf1);
  color: var(--color-text-secondary, #606577);
  cursor: not-allowed;
}

/* Mobile responsiveness */
@media (max-width: 767px) {
  .error-report-container {
    padding: 15px;
    width: 90%;
  }
  
  .styled-select {
    width: calc(100% - 30px);
  }
  
  .error-textarea {
    width: calc(100% - 20px);
  }
  
  .action-btn {
    width: 100%;
  }
}
