





































































































































































































































































































































































































































































































































































.modal-overlay {
        position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(15, 15, 26, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}

.modal-container.workspaces-modal {
  background-color: var(--color-background, white);
  border-radius: 8px;
  width: 95%;
  max-width: 420px;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 20px rgba(15, 15, 26, 0.15);
  color: var(--color-text-primary, #0F0F1A); 
}

.modal-header {
  padding: 16px 20px;
  border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.title-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header-icon {
  color: var(--primary, #00AEDD);
  font-size: 22px;
}

.modal-title {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.modal-close-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-text-secondary, #606577);
  padding: 4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  transition: background-color 0.2s ease-in-out;
}

.modal-close-btn:hover {
  background-color: var(--color-surface, #F3F4F7);
}

.modal-close-btn i {
  font-size: 20px;
}

.modal-body {
  padding: 15px 20px;
  overflow-y: auto;
  flex-grow: 1;
}

.modal-body::-webkit-scrollbar {
    width: 8px;
}
.modal-body::-webkit-scrollbar-track {
    background: var(--color-surface, #F3F4F7);
    border-radius: 4px;
}
.modal-body::-webkit-scrollbar-thumb {
    background-color: var(--color-text-secondary, #B0B4C3);
    border-radius: 4px;
    border: 2px solid var(--color-surface, #F3F4F7);
}

.ticker-info {
    background-color: var(--color-surface, #F3F4F7);
    padding: 10px 15px;
    border-radius: 6px;
        margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ticker-label {
    color: var(--color-text-secondary, #606577);
    font-size: 14px;
    }

    .ticker-name {
    font-size: 16px;
    font-weight: 600;
    color: var(--color-text-primary, #0F0F1A);
}

.section-title {
    font-size: 13px;
    font-weight: 600;
    color: var(--color-highlight, #00AEDD);
    text-transform: uppercase;
    margin: 0 0 10px 0;
    letter-spacing: 0.5px;
}

.list-container {
    display: flex;
    flex-direction: column;
    gap: 5px; 
}

.list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 5px;
    border-radius: 6px;
    transition: background-color 0.2s ease-in-out;
}

.list-item.clickable-item {
    cursor: pointer;
}

.list-item.clickable-item:not(:has(.edit-input-wrapper)):hover {
    background-color: var(--color-surface, #F3F4F7);
}

.list-item.clickable-item:not(:has(.edit-input-wrapper)):hover .item-text {
     color: var(--primary, #00AEDD);
}

.item-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.workspace-selector {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-grow: 1;
    overflow: hidden;
}

.radio-icon {
    font-size: 20px;
    color: var(--primary, #00AEDD);
}

.star-icon {
    font-size: 20px;
}

.item-text {
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.workspace-name {
    color: var(--color-text-primary, #0F0F1A);
}

.item-actions {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-shrink: 0;
}

.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-text-secondary, #606577);
    transition: color 0.2s ease, background-color 0.2s ease;
}

.icon-button:hover {
    background-color: var(--color-muted, #EAECEF); 
}

.icon-button i,
.icon-button span.material-icons-outlined {
    font-size: 18px;
}

.delete-icon:hover {
    color: var(--color-error, #B00020);
}
.edit-icon:hover {
    color: var(--primary, #00AEDD);
}
.confirm-edit-btn:hover, 
.confirm-create-btn:hover {
    color: var(--color-success, #57a869);
}
.cancel-edit-btn:hover, 
.cancel-create-btn:hover {
    color: var(--color-error, #B00020); 
}

.edit-input-wrapper,
.create-workspace-form {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 2px 0;
}

.color-swatch-edit,
.color-swatch-create {
    margin-right: 8px;
    flex-shrink: 0;
}

.color-trigger-btn {
    padding: 0;
    width: 28px; 
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.color-trigger-btn .star-icon {
     font-size: 20px; 
}

.vanilla-input {
    flex-grow: 1;
    margin-right: 8px;
    height: 34px;
    padding: 6px 10px;
    font-size: 14px;
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    border-radius: 4px;
    background-color: var(--color-background, white);
    color: var(--color-text-primary, #0F0F1A);
    transition: border-color 0.2s ease;
    box-sizing: border-box;
}
.vanilla-input:focus {
    outline: none;
    border-color: var(--primary, #00AEDD);
    box-shadow: 0 0 0 2px rgba(0, 174, 221, 0.2);
}
.vanilla-input::placeholder {
    color: var(--color-text-secondary, #606577);
    opacity: 0.6;
}

.add-workspace-section {
    margin-top: 15px;
    display: flex;
    justify-content: center;
}

.add-workspace-btn {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 38px;
    background-color: var(--color-surface, #F3F4F7);
    color: var(--primary, #00AEDD);
    border: 1px dashed var(--color-outline, rgba(0,0,0,0.2));
    border-radius: 6px;
}

.add-workspace-btn:hover {
     background-color: var(--color-muted, #EAECEF); 
}

.add-workspace-btn i {
    font-size: 20px;
}

.delete-confirm-view {
    padding: 10px 0;
        text-align: center;
}

.delete-question-text {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    line-height: 1.4;
}

.workspace-to-delete-info {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    background-color: var(--color-surface, #F3F4F7);
    padding: 8px 15px;
    border-radius: 6px;
    margin-bottom: 15px;
    color: var(--color-text-primary, #0F0F1A);
}

.workspace-name-delete {
    font-weight: 600;
}

.delete-warning {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: var(--color-warning, #FF9B00);
    font-size: 14px;
    margin-top: 10px;
    line-height: 1.3;
}

.warning-icon {
    font-size: 20px;
    flex-shrink: 0;
}

.modal-footer {
    padding: 16px 20px;
    border-top: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    flex-shrink: 0;
}

.action-btn {
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    padding: 0 16px;
    height: 36px;
    transition: all 0.2s ease;
    font-weight: 600;
}

.secondary-btn {
    background-color: var(--color-surface, #F3F4F7);
    color: var(--color-text-primary, #0F0F1A);
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
}
.secondary-btn:hover {
    background-color: var(--color-muted, #EAECEF); 
}

.delete-btn {
    background-color: var(--color-error, #B00020);
    color: white;
}
.delete-btn:hover {
    background-color: #9a001b;
}

.color-grid-popup {
    position: fixed;
    background-color: var(--color-background, white);
    border-radius: 8px;
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.25);
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(4, 24px);
    grid-template-rows: repeat(4, 24px);
    gap: 8px;
    z-index: 9999;
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.1));
}

.color-grid-item {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.1));
    transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.color-grid-item:hover {
    transform: scale(1.15);
    border-color: var(--color-text-primary, #0F0F1A);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.color-grid-item:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--primary, #00AEDD);
    transform: scale(1.15);
    }
