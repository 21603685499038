





























































































#sidebar-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
}

#sidebar-header-container>* {
  position: relative !important;
  left: unset !important;
  top: unset !important;
}
