





































































































































































































































































































































































































































































































































































































































@import "@/components/stockchart/styles/stockchart.scss";
@import "@/styles/basics/globals.scss";

html {
  overflow-y: hidden;
}

.stockchart-default{    
  z-index: 1;
  overflow: hidden;
}

.mobile-content {
  overflow-x: hidden;
  overflow-y: scroll;
}

#sidebar-content {
  border-left: 1px solid grey;
  color: var(--color-text-secondary);
  background: #FFFFFF;
  margin: 0;
  padding: 0;
  height: 95%;
  position: fixed;
  width: 1px;
  top: 77px;
  right: 80px;
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;

  .components {      
    > div {
      display:none;
      // height: calc(100vh); 
      }

    header {
      color: var(--color-typography-on-secondary);
      background: var(--color-primary-secondary);
      text-align: center;
      margin: 0;
      border-bottom: 1px solid white;
      margin-bottom: 20px;

      span.full-width {
        position: absolute;
        left: 10px;
        top: 20px;
        cursor: pointer;}

      h2 {
        margin: 4px 0px 4px 4px;}
    }
  }
}

.flex-container{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.searches-tittle{
  background-color: #000010;
  text-align: center;
  height: 40px;
  line-height: 40px;
}

.close-searches{
  font-size: 16px;
  right: 30px;
  position: relative;
  margin-top: 10px;
  color: white;
  float: right;
}

.text-searches{
  margin-top: 10px !important;
  color: white;
}

.cartera-mobile{
  position: fixed;
  z-index: 4;
  background-color: transparent;
  top: 12px;
  right: 0;
  width: 40%;
  margin-right: 15px;
}

.iframe-container{
  height: 950px;
}

.pop-up-streaming{
  text-align:center;
  border: 1px solid black;
  width: 40%;
  height: 30%;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.5);
}

.flex-container.mobile-content{
  background-color: var(--custom-color-background-color-stockchart);
}

#sidebar-content {
  background-color: var(--color-surface);
}
.streaming-container{
  background-color: rgba(146, 146, 146, 0.15);;
  height: 100%;
  width: 100%;
}

.loading-hidden {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.loading-visible {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.full-screen-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: var(--color-background, #fff);
  display: flex;
  justify-content: center;
  align-items: center;
}
