




















































































































































































.information-mobile{
  display: flex;
}

.counter-stocks{
  min-width: 95px;
  height: 45px;
  left: 0px;
  top: 121px;
  border-top: 1px solid grey;
  background: var(--color-primary-primary);
  color: white;
  font-size: 25px;
  text-align:center;
}

.tickers-name{
  box-sizing: border-box;
  height: 36px;
  left: 95px;
  top: 121px;
  min-width: 60px;

/* Tema Claro/03. Background/Background */

  background: var(--color-custom-ticker-name-mobile);
/* Tema Claro/03. Background/Outline */

  border: 1px solid rgba(15, 15, 26, 0.2);
  text-align:center;
}

.ticker-box{
  height: 20px;
  left: 24.87%;
  right: 56.92%;
  top: calc(50% - 20px/2 - 282px);
  padding: 6px;

  /* Body 2 */

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;

  /* Tema Claro/01. Accent/400-Accent */

  color: var(--color-custom-ticker-name-mobile-color);

}
