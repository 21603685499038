







































































































































































































































@import "@/styles/basics/globals.scss";

p {
  margin: 0;
  padding: 0;
  line-height: 24px;
  color: #7D8294;
}

span {
  color: black;
}

a {
  text-decoration: none;
  color: #35add8;
}

hr {
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin: 22px 5%;
}

.info-ticker-box {
   width: 100%;
   display: flex;
   justify-content: center;
}
.text-info-ticker {
  text-align: center;
  background-color: #F3F4F7;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 24px;
  width: 130px;
  padding: 7px 20px;
  color: black;
}

.text-bold {
  font-weight: bold;
}

.text-info-grey {
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.25px;
  color: #7D8294;
}

.text-info-dark {
  color: #000000;
}

.card-info {
  margin: 0 35px;
  //display: flex;
  flex-wrap: wrap;
}

.card-info-box {
  width: 100%;
}

.is-fullscreen {
  width: 30%;
  margin: 0 10%
}

#text-is-fullscreen {
  margin-bottom: 50px;
}

#remaining-text{
  display: none;
}

.read-more-btn{
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: grey;
}

.read-more-btn-container{
  text-align: center;
}

#stock-info .text-info-ticker, #stock-info .text-info-ticker span {
  background-color: var(--custom-color-right-menu-highlight);
  color: var(--custom-color-right-menu-font);
}

.not-allowed-eurekers-analysis-container{
  text-align: center;
  padding: 20px;
}

.not-allowed-eurekers-analysis{
  color: #FF9B00;
}

.not-available-data-container{
  text-align: center;
}

.not-available-data{
  color: #FF9B00;
}

.not-allowed-data{
  color: #FF9B00 !important;
}

.link-to-store{
  color: #35add8;
}
