
















































































































































































































































































































































































@import "@/styles/basics/globals.scss";

#alarms {
  width: 100%;
}

.alarm-container {
  max-width: 600px;
  margin: 20px auto;
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--color-background);
  box-shadow: 0 2px 10px rgba(15, 15, 26, 0.08);
  width: 85%;
}

.info-ticker-box {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.text-info-ticker {
  text-align: center;
  background-color: var(--color-surface, #F3F4F7);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding: 8px 30px;
  border-radius: 20px;
  color: var(--color-text-primary);
  font-weight: 500;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.text-bold {
  font-weight: 700;
  color: var(--primary, #00aedd);
}

.operation-config-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 25px 0;
}

.select-wrapper, .input-wrapper {
  width: 100%;
}

.input-label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-text-primary, #0F0F1A);
  text-align: left;
}

.styled-select {
  width: 100%;
  height: 40px;
  padding: 8px 15px;
  font-size: 16px;
  border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
  border-radius: 4px;
  background-color: var(--color-background, white);
  color: var(--color-text-primary, #0F0F1A);
  appearance: none;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23606577'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px;
  box-sizing: border-box;
}

.styled-select:focus {
  outline: none;
  border-color: var(--primary, #00aedd);
  box-shadow: 0 0 0 2px rgba(0, 174, 221, 0.1);
}

.styled-input {
  width: 100%;
  height: 40px;
  padding: 8px 15px;
  font-size: 16px;
  border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
  border-radius: 4px;
  background-color: var(--color-background, white);
  color: var(--color-text-primary, #0F0F1A);
  -moz-appearance: textfield; /* Firefox */
  box-sizing: border-box;
}

.styled-input::-webkit-outer-spin-button,
.styled-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.styled-input:focus {
  outline: none;
  border-color: var(--primary, #00aedd);
  box-shadow: 0 0 0 2px rgba(0, 174, 221, 0.1);
}

.btns-container {
  margin: 30px 0;
  text-align: center;
}

.action-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  padding: 0 16px;
  height: 40px;
  transition: all 0.2s ease;
  min-width: 200px;
  font-weight: 600;
}

.primary-btn {
  background-color: var(--primary, #00aedd);
  color: white;
  box-shadow: none;
}

.primary-btn:hover {
  background-color: var(--color-muted, #35add8);
  box-shadow: none;
}

.separator {
  border: none;
  border-top: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
  margin: 25px 0;
}

.pending-alarms-section, .executed-alarms-section {
  margin-top: 20px;
}

.executed-alarms-section {
  margin-top: 25px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
}

.section-title {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-text-primary, #0F0F1A);
}

.expand-icon {
  color: var(--color-text-secondary, #606577);
  font-size: 24px;
  transition: transform 0.3s ease;
}

.expand-icon.expanded {
  transform: rotate(180deg);
}

.pending-alarms-list {
  margin-top: 10px;
}

.pending-alarm-item, .executed-alarm-item {
  margin-bottom: 12px;
  border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
  border-radius: 5px;
  padding: 5px 0;
}

.pending-alarm-item {
  border-left: 5px solid var(--primary, #00aedd);
}

.executed-alarm-item {
  border-left: 5px solid var(--color-success, #57a869);
}

.alarm-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}

.alarm-date, .execution-date {
  font-size: 14px;
  font-weight: 600;
  color: var(--color-text-primary, #0F0F1A);
  margin: 0;
}

.execution-date {
  font-size: 12px;
  color: var(--color-success, #57a869);
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-button i {
  font-size: 18px;
  color: var(--color-text-secondary, #606577);
  transition: color 0.2s ease;
}

.delete-button:hover i {
  color: var(--color-error, #B00020);
}

.alarm-description {
  font-size: 12px;
  line-height: 1.4;
  color: var(--color-text-primary, #0F0F1A);
  padding: 0 15px 10px;
  margin: 0;
}

.no-alarms-message {
  text-align: center;
  padding: 20px 0;
  color: var(--color-text-secondary, #606577);
  font-style: italic;
}

/* Mobile responsiveness */
@media (max-width: 767px) {
  .alarm-container {
    width: 90%;
    padding: 15px;
  }
  
  .operation-config-container {
    gap: 12px;
  }
  
  .action-btn {
    width: 100%;
  }
}
