

















































































































































































@import "@/styles/basics/globals.scss";

.error-report {
  text-align: center;
}

.error-report {
    margin:20px;
}

.select_material_style {
  margin-top: 30px; 
  width: 100%;
  background-color: transparent;
  padding-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  height: 56px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  color: #000010;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.mdc-button {
    margin-top: 10px;
}

.info-ticker-box {
   width: 100%;
   display: flex;
   justify-content: center;
}
.text-info-ticker {
  text-align: center;
  background-color: #F3F4F7;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 26px;
  width: 130px;
  padding: 7px 20px;
}

.text-bold {
  font-weight: bold;
}

.helper-text-error {
  text-align: left;
  margin-left: 10px;
  margin-bottom: 38px;
}

#error-helper-text {
  margin-top: 3px;
  font-size: 12px;
}

.error-box {
  display: flex;
  align-items: center;
  width: auto;
}

.form-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#select-error-box {
  width: 100%;
}
.mobile-content {
  margin-top: 15px;
  height: 460px;
  min-height: 420px;
  overflow-y: scroll;
}

.header-container{
  display: flex;
  justify-content: center;
  padding: 10px 0px 35px 0px !important;
}

.title-container{
  margin-left: 30px;
  font-size: 20px;
  font-weight: 600;
}

.close-btn-container{
  margin-left: auto;
  order: 2;
  margin-right: 10px;
}

