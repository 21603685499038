
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































.mobile-stockchart-menu {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  text-align: center;
  padding-bottom: 40px;

  min-width: 360px;
  z-index: 20;
  ul {
    padding: 0;
    li {
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.12);

    }
  }

  background-color: var(--custom-color-stockchart-mobile-buttons);
  color: var(--color-custom-typo-mobile-buttons-stockchart);
}

.bottom-space-margin {
  padding-bottom: 20px;
  margin-bottom: 70px;
  border: none;
}

#main-container {
  box-shadow: 0px 10px 5px #888, 0px 0px 40px #888;
}

.mdc-list-item {
  justify-content: center;
  width: 80px;
  padding: 15px 0;
  margin: 0;
  border: 1px solid var(--color-custom-border-mobile-buttons-stockchart);
  margin-bottom: -17px;
}

.mdc-list-item__text,
.mdc-list-item__text span {
  font-size: 12px;
}

.top-row {
  margin-top: 0;
}
