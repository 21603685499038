































































































































































































































.ai-fullscreen {      
  background: rgba(0, 0, 0, 0.15);
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  position: fixed;
  z-index: -2;
}
#white-window {
  height: 500px;
  overflow-y: scroll;
  z-index: 1;
  left: 1645px;
  top: 66px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  width: 119px;
  margin-left: 5px;
}
#mobile-white-window{
  height: 250px;
  overflow-y: scroll;
  z-index: 1;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

#tools{
  font-size:12px;
  margin: 0;
  padding: 15px 0;
  text-align:center;
}

#tool-window-container {
  cursor: pointer;
}

#tool-window-container:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

