



































































































































































.mobile-stockchart-menu {
  background-color: var(--color-primary-primary);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  text-align: center;

  min-width: 64px;
  z-index: 20;
  ul {
    padding: 0;
    li {
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.12);




    }
  }
}

.mobile-top {
  justify-content: center;
  width: 80px;
  padding: 14px 0;
  margin: 0;
}

.top-menu-mobile-schart {
  margin-bottom: 0;
}

.mdc-list.top-menu-mobile-schart {
  margin: 0 !important;
}
