





































































































































































































































.comment-box {
  display: flex;
  align-items: center;
  margin: 20px 0px;
}
.material-icons-outlined {
  cursor: pointer;
}
.info-ticker-box {
   width: 100%;
   display: flex;
   justify-content: center;
}
.text-info-ticker {
  text-align: center;
  background-color: #F3F4F7;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 24px;
  width: 130px;
  padding: 7px 20px;
}
.text-bold {
  font-weight: bold;
}
.comment-count-text {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 12px;
  margin: 10px 0 20px;
}
hr {
  color: rgba(0, 0, 0, 0.12);
  margin: 20px 0 24px;
}
#comment-user .user-picture  {
  display: none;
}
#comment-user {
  font-size: 14px;
  width: 100%;
}
#comment-text,
.no-comment-text {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #121212;
}
#comment-rating {
  display: flex;
  justify-content: end;
  align-items: center;
}
.comment-icon {
  margin: 0 2px;
  cursor: pointer;
}
.comment-text {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-left: 2px;
}
.text-bold {
  font-weight: bold;
}
.user-box {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
#comment {
  margin: 0 20px;
}
.comment-icon-user {
  font-size: 40px;
  margin-right: 15px;
}  
#comment-writer{
  background:white;
  border-top: 0.5px solid rgba(0, 0, 0, 0.12);
  width: 100%; 
}
.comment-box{
  width:95%;
  margin-left:10px;
}
#comments-wrapper{
  margin-bottom:200px;
}
.first-line{
  width: 100%;
}
#stock-comments{
  width:100%;
  margin-left:3px;
}

.mobile-content {
  margin-top: 15px;
  height: 460px;
  min-height: 420px;
  overflow-y: scroll;
}

.header-container{
  display: flex;
  justify-content: center;
  padding: 10px 0px 35px 0px !important;
}

.title-container{
  margin-left: 30px;
  font-size: 20px;
  font-weight: 600;
}

.close-btn-container{
  margin-left: auto;
  order: 2;
  margin-right: 10px;
}

.not-allowed-forum{
  text-align: center;
  color: #FF9B00;
  font-weight: 500;
}

.not-available-data-container{
  text-align: center;
}

.not-available-data{
  color: #FF9B00;
}

.link-to-store{
  color: #35add8;
}

