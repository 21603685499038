

























































































































































































































































































































































































































































































































































/* Remove old fullscreen and positioning styles */
/* #workspace-favorites-fullscreen { ... } */
/* .favorites-workspace-container-style { ... } */
/* .user-workspaces { ... } */

/* Basic modal content styling */
.favorites-modal-content {
    background-color: var(--color-background, white);
    border-radius: 8px;
    width: 380px; /* Slightly wider for color picker */
    max-height: 85vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    color: var(--color-text-primary, #0F0F1A);
    box-shadow: 0 4px 20px rgba(15, 15, 26, 0.15);
}

/* Modal Header */
.modal-header {
    padding: 16px 20px;
    border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
}

.title-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.header-icon {
    color: var(--primary, #00AEDD); /* Use theme primary */
    font-size: 22px;
}

.modal-title {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}

.modal-close-btn {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--color-text-secondary, #606577);
    padding: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    transition: background-color 0.2s ease-in-out;
}

.modal-close-btn:hover {
    background-color: var(--color-surface, #F3F4F7);
}

.modal-close-btn i {
    font-size: 20px;
}

/* Modal Body */
.modal-body {
    padding: 15px 20px;
    overflow-y: auto;
    flex-grow: 1;
}

/* Scrollbar styles */
.modal-body::-webkit-scrollbar {
    width: 8px;
}
.modal-body::-webkit-scrollbar-track {
    background: var(--color-surface, #F3F4F7);
    border-radius: 4px;
}
.modal-body::-webkit-scrollbar-thumb {
    background-color: var(--color-text-secondary, #B0B4C3);
    border-radius: 4px;
    border: 2px solid var(--color-surface, #F3F4F7);
}

/* List styles */
.list-container {
    display: flex;
    flex-direction: column;
    gap: 8px; /* Slightly more gap */
}

.list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px; /* Adjust padding */
    border-radius: 6px;
    /* Remove cursor pointer from the whole item if info part is clickable */
    /* cursor: pointer; */ 
    transition: background-color 0.2s ease-in-out;
}

/* Only apply hover background, not color change, actions appear on hover */
.list-item:hover {
    background-color: var(--color-surface, #F3F4F7);
}

.item-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
        width: 100%;
}

.item-info {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer; /* Make info part clickable */
    flex-grow: 1;
    overflow: hidden; /* Prevent long names breaking layout */
}

.item-icon {
    font-size: 20px; /* Standard icon size */
    flex-shrink: 0;
}

.item-text {
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-text-primary, #0F0F1A);
}

.list-item:hover .item-info .item-text {
    color: var(--primary, #00AEDD);
}

.item-actions {
    display: flex; /* Always visible */
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
}

/* Show actions on list item hover */
// /* Remove rule: actions are now always visible */
// .list-item:hover .item-actions {
//     display: flex;
// }

/* Icon button base style */
.icon-button {
    background: none;
    border: none;
        cursor: pointer;
    padding: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-text-secondary, #606577);
    transition: color 0.2s ease, background-color 0.2s ease;
}

.icon-button:hover {
    background-color: var(--color-muted, #EAECEF);
}

.icon-button i,
.icon-button .material-icons-outlined /* For color picker trigger */
{
    font-size: 18px;
}

.delete-icon:hover {
    color: var(--color-error, #B00020);
}

.edit-icon:hover {
    color: var(--primary, #00AEDD);
}

.confirm-edit-btn:hover {
    color: var(--color-success, #57a869);
}

.cancel-edit-btn:hover {
    color: var(--color-error, #B00020);
}

/* Edit and Create Input Wrappers */
.edit-input-wrapper,
.create-input-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 4px 0; /* Add slight vertical padding */
}

/* Vanilla Input Styles */
.vanilla-input {
    flex-grow: 1;
    margin: 0 10px; /* Margin left and right */
    height: 36px;
    padding: 6px 12px;
    font-size: 14px;
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    border-radius: 4px;
    background-color: var(--color-background, white);
    color: var(--color-text-primary, #0F0F1A);
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
    box-sizing: border-box;
}

.vanilla-input:focus {
    outline: none;
    border-color: var(--primary, #00AEDD);
    box-shadow: 0 0 0 2px rgba(0, 174, 221, 0.2);
}

/* Color Picker Styles */
.color-picker {
    flex-shrink: 0;
    /* Adjustments for VSwatches */
    margin-left: 0; 
    z-index: 10; /* Ensure popover is above other modal content */
}

.color-trigger-btn {
     padding: 6px; /* Match icon button padding */
}

.color-trigger-btn .material-icons-outlined {
    font-size: 22px; /* Slightly larger for color star */
    display: block; /* Prevent extra space */
}

/* Add New Section */
.add-new-section {
    margin-top: 15px;
    text-align: center;
}

.add-workspace-btn {
    display: inline-flex; /* Align icon and text */
    align-items: center;
    gap: 8px;
    width: auto; /* Adjust width automatically */
    padding: 0 20px; /* More horizontal padding */
}

.add-workspace-btn i {
    font-size: 20px;
}

hr {
    border: none;
    height: 1px;
    background-color: var(--color-outline, rgba(0, 0, 0, 0.12));
    margin: 15px 0;
}

/* Delete Confirmation View */
.delete-confirm-view {
    padding: 20px 0; /* More padding */
        text-align: center;
}

.delete-question-text {
    font-size: 16px;
    font-weight: 500;
        margin-bottom: 20px;
    }

.item-to-delete-info {
    display: inline-flex;
    align-items: center;
        gap: 10px;
    background-color: var(--color-surface, #F3F4F7);
    padding: 10px 18px;
    border-radius: 6px;
    margin-bottom: 20px;
    color: var(--color-text-primary, #0F0F1A);
}

.item-to-delete-info .item-icon {
    font-size: 22px; /* Match header icon size */
}

.item-name-delete {
    font-weight: 600;
    font-size: 15px;
}

.delete-warning {
      display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: var(--color-warning, #FF9B00);
    font-size: 14px;
    margin-top: 15px;
}

.warning-icon {
    font-size: 20px;
}

/* Modal Footer */
.modal-footer {
    padding: 16px 20px;
    border-top: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
      display: flex;
    justify-content: flex-end;
    gap: 12px;
    flex-shrink: 0;
}

/* Action Buttons */
.action-btn {
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    padding: 0 16px;
    height: 36px;
    transition: all 0.2s ease;
    font-weight: 600;
}

.secondary-btn {
    background-color: var(--color-surface, #F3F4F7);
    color: var(--color-text-primary, #0F0F1A);
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
}

.secondary-btn:hover:not(:disabled) {
    background-color: var(--color-background, #EAECEF);
}

.secondary-btn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.delete-btn {
    background-color: var(--color-error, #B00020);
    color: white;
}

.delete-btn:hover {
    background-color: #9a001b;
}


/* VSwatches Overrides */
::v-deep .vue-swatches__container {
    padding: 0 !important; 
    z-index: 10; /* Ensure popover is above other modal content */
}

::v-deep .vue-swatches__wrapper {
    padding-left: 5px !important; /* Align swatches nicely */
    padding-bottom: 5px !important;
}

::v-deep .vue-swatches__swatch {
    margin-top: 5px !important;
    margin-right: 5px !important;
    border-radius: 50% !important;
    box-shadow: none !important; 
}

::v-deep .vue-swatches__swatch .vue-swatches__check__wrapper {
    border-radius: 50% !important;
}

::v-deep .vue-swatches__check__icon {
     /* Make check icon smaller/thinner if needed */
}

/* Custom Color Grid Popup Styles */
.color-grid-popup {
    /* Change from absolute to fixed (set in JS) */
    background-color: var(--color-background, white);
    border-radius: 8px;
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.25);
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(4, 24px); /* 4 columns */
    grid-template-rows: repeat(4, 24px);    /* 4 rows */
    gap: 8px;
    z-index: 9999; /* Ensure it's above everything */
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.1));
}

.color-grid-item {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.1));
    transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.color-grid-item:hover {
    transform: scale(1.15);
    border-color: var(--color-text-primary, #0F0F1A);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.color-grid-item:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--primary, #00AEDD);
    transform: scale(1.15);
}

