





























































































































































































































































































































































































































































































































































































































































































































@import "@/styles/basics/globals.scss";

#fundamental {
  width: 100%;
}

.fundamental-content-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--color-background);
  box-shadow: 0 2px 10px rgba(15, 15, 26, 0.08);
  width: 85%;
}

.info-ticker-box {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.text-info-ticker {
  text-align: center;
  background-color: var(--color-surface, #F3F4F7);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding: 8px 30px;
  border-radius: 20px;
  color: var(--color-text-primary);
  font-weight: 500;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.text-bold {
  font-weight: 700;
  color: var(--primary, #00aedd);
}

.info-sections {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.info-section {
  background-color: var(--color-surface, #F3F4F7);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(15, 15, 26, 0.05);
  transition: transform 0.2s, box-shadow 0.2s;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(15, 15, 26, 0.08);
  }
}

.section-header {
  background-color: var(--color-active-surface, rgba(0, 174, 221, 0.08));
  padding: 12px 20px;
  border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.08));
  
  h3 {
    margin: 0;
    color: var(--primary, #00aedd);
    font-size: 16px;
    font-weight: 600;
    text-align: left;
  }
}

.section-content {
  padding: 15px 20px;
}

.info-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px 0;
  border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.04));
  
  &:last-child {
    border-bottom: none;
  }
}

.info-label {
  color: var(--color-text-secondary, #606577);
  font-size: 14px;
  font-weight: 500;
  flex-shrink: 0;
  margin-right: 15px;
  width: 40%;
  text-align: left;
}

.info-value {
  color: var(--color-text-primary);
  font-size: 14px;
  font-weight: 600;
  width: 60%;
  text-align: right;
  word-break: break-word;
}

.chart-container {
  margin: 15px 0;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid var(--color-outline, rgba(15, 15, 26, 0.08));
  background-color: var(--color-surface, #F3F4F7);
  height: 250px;
  position: relative;
}

.financial-chart-container {
  margin-bottom: 25px;
  
  &:last-child {
    margin-bottom: 0;
  }
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.chart-title {
  color: var(--color-text-secondary, #606577);
  font-size: 14px;
  font-weight: 500;
}

.chart-currency {
  color: var(--color-text-primary);
  font-size: 14px;
  font-weight: 600;
  padding: 3px 8px;
  background-color: var(--color-active-surface, rgba(0, 174, 221, 0.08));
  border-radius: 12px;
}

.table-container {
  overflow-x: auto;
  margin: 10px 0;
}

.website-row {
  justify-content: center;
}

.website-link {
  color: var(--primary, #00aedd);
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  
  &:hover {
    text-decoration: underline;
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  padding: 30px 0;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid var(--color-outline, rgba(0, 0, 0, 0.08));
  border-radius: 50%;
  border-top: 4px solid var(--primary, #00aedd);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.no-data-container {
  max-width: 600px;
  margin: 20px auto;
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--color-background);
  box-shadow: 0 2px 10px rgba(15, 15, 26, 0.08);
  width: 85%;
}

.no-data-message {
  color: var(--color-text-secondary, #606577);
  font-size: 14px;
  padding: 15px 0;
}

.not-available-data-container {
  max-width: 600px;
  margin: 20px auto;
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--color-background);
  box-shadow: 0 2px 10px rgba(15, 15, 26, 0.08);
  width: 85%;
  text-align: center;
}

.not-available-data {
  color: var(--warning, #FF9B00);
  font-size: 16px;
  margin-bottom: 15px;
}

.upgrade-link {
  color: var(--primary, #00aedd);
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
  
  &:hover {
    text-decoration: underline;
  }
}

#highchart-content-balance,
#highchart-content-employees,
#highchart-content-cash-flow,
#highchart-content-income-statement,
#highchart-content-earnings,
#highchart-content-outstanding-shares {
  height: 100%;
  width: 100%;
  border: none;
  position: relative;
  overflow: visible;
}

.highcharts-container {
  width: 100% !important;
  height: 100% !important;
  overflow: visible !important;
}

.highcharts-root {
  width: 100% !important;
  height: 100% !important;
  overflow: visible !important;
}

/* Fix for Highcharts responsive layout in this component */
:deep(.highcharts-container),
:deep(.highcharts-root) {
  width: 100% !important;
  height: 100% !important;
  overflow: visible !important;
}

:deep(.highcharts-series-group) {
  overflow: visible !important;
}

:deep(.highcharts-point) {
  stroke-width: 0;
}

:deep(.highcharts-grid-line) {
  stroke-dasharray: 2, 2;
}

:deep(.highcharts-plot-border) {
  stroke-width: 0;
}

/* Mobile responsiveness */
@media (max-width: 767px) {
  .fundamental-content-container,
  .no-data-container,
  .not-available-data-container {
    padding: 15px;
    width: 92%;
  }
  
  .info-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    padding: 10px 0;
  }
  
  .info-label {
    font-weight: 600;
    margin-bottom: 4px;
    width: 100%;
  }
  
  .info-value {
    width: 100%;
    text-align: left;
  }
  
  .section-header h3 {
    font-size: 15px;
  }
  
  .chart-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
}

/* Custom table styles */
.custom-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  background-color: var(--color-background);
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 5px;
}

.custom-table th {
  background-color: var(--color-muted, #00aedd);
  color: white;
  font-weight: 600;
  text-align: left;
  padding: 12px 15px;
  border-bottom: 1px solid var(--color-outline, rgba(15, 15, 26, 0.12));
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.custom-table th:last-child {
  border-right: none;
}

.custom-table td {
  color: var(--color-text-primary);
  padding: 10px 15px;
  border-bottom: 1px solid var(--color-outline, rgba(15, 15, 26, 0.08));
  border-right: 1px solid var(--color-outline, rgba(15, 15, 26, 0.08));
  background-color: var(--color-background);
}

.custom-table td:last-child {
  border-right: none;
}

.custom-table tr:last-child td {
  border-bottom: none;
}

.custom-table tr:hover td {
  background-color: var(--color-active-surface, rgba(0, 174, 221, 0.08));
}
