









































































































































@import "@/styles/basics/globals.scss";

#description {
  width: 100%;
}

.description-content-container {
  max-width: 600px;
  margin: 20px auto;
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--color-background);
  box-shadow: 0 2px 10px rgba(15, 15, 26, 0.08);
  width: 85%;
}

.info-section {
  background-color: var(--color-surface, #F3F4F7);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(15, 15, 26, 0.05);
  margin-bottom: 22px;
  transition: transform 0.2s, box-shadow 0.2s;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(15, 15, 26, 0.08);
  }
  
  &:last-child {
    margin-bottom: 0;
  }
}

.section-header {
  background-color: var(--color-active-surface, rgba(0, 174, 221, 0.08));
  padding: 12px 20px;
  border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.08));
  
  h3 {
    margin: 0;
    color: var(--primary, #00aedd);
    font-size: 16px;
    font-weight: 600;
    text-align: left;
  }
}

.section-content {
  padding: 15px 20px;
}

.info-ticker-box {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.text-info-ticker {
  text-align: center;
  background-color: var(--color-surface, #F3F4F7);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding: 8px 30px;
  border-radius: 20px;
  color: var(--color-text-primary);
  font-weight: 500;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.text-bold {
  font-weight: 700;
  color: var(--primary, #00aedd);
}

.sector-industry {
  margin-bottom: 12px;
  font-weight: 600;
  color: var(--color-text-secondary, #606577);
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.sector-label, .industry-label {
  display: inline-block;
}

.divider {
  margin: 0 8px;
  color: var(--color-outline, rgba(0, 0, 0, 0.3));
}

.ticker-description {
  margin: 0;
  padding: 0;
  color: var(--color-text-primary);
  font-size: 14px;
  line-height: 1.6;
  text-align: left;
}

.no-data-message {
  color: var(--color-text-secondary, #606577);
  font-size: 14px;
  font-style: italic;
  text-align: center;
  margin: 10px 0;
}

.not-available-container {
  max-width: 600px;
  margin: 30px auto;
  padding: 20px;
  text-align: center;
}

.not-available-message {
  color: var(--warning, #FF9B00);
  font-size: 16px;
  margin-bottom: 15px;
}

.upgrade-link {
  color: var(--primary, #00aedd);
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
  
  &:hover {
    text-decoration: underline;
  }
}

.website-section {
  margin-bottom: 22px;
}

.website-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.website-link {
  color: var(--primary, #00aedd);
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 4px;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: var(--color-active-surface, rgba(0, 174, 221, 0.08));
    text-decoration: underline;
  }
}

/* Mobile responsiveness */
@media (max-width: 767px) {
  .description-content-container {
    padding: 15px;
    width: 92%;
  }
  
  .section-header h3 {
    font-size: 15px;
  }
  
  .ticker-description {
    font-size: 14px;
  }
}
