$sidebar: 64px;
$open_sidebar: 315px;
$eurekers_color: #00add8;
$base_background_color: var(--color-background-surface);
$main_color_white: #ffffff;
$border_color: #2f3241;
.stockchart-menu {
  height: 100%;
  background-color: $base_background_color;

  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);

  position: absolute;
  left: $sidebar;
  min-width: 64px;
  z-index: 4;
  ul {
    padding: 0;
    li {
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.12);
      border-color: var(--color-custom-border);




    }
  }
}

.stockchart-menu {
  border-right: 1px solid var(--color-custom-only-dark-theme-border);
}

.stockchart-menu span {
  color: var(--color-typography-on-background) !important;
}

.mdc-list-item {
  justify-content: center;
  width: 80px;
  padding: 14px 0;
  margin: 0;
}

#favorites-container span{
  color: black !important;
}