















































































































































































































































































@import "@/styles/basics/globals.scss";

#stock-comments {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.content-container {
  border-radius: 10px;
  position: relative;
  background-color: var(--color-background);
  margin: 20px auto;
  padding: 20px 20px 120px; /* Increase bottom padding significantly to ensure space for comment writer */
  display: flex;
  flex-direction: column;
  width: 85%;
  flex: 1;
  overflow: hidden; /* Hide overflow */
  height: calc(100% - 40px); /* Account for margins */
}

.info-ticker-box {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.text-info-ticker {
  text-align: center;
  background-color: var(--color-surface, #F3F4F7);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding: 8px 30px;
  border-radius: 20px;
  color: var(--color-text-primary);
  font-weight: 500;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.text-bold {
  font-weight: 700;
  color: var(--primary, #00aedd);
}

.comments-header-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 5px; /* Adjusted margin */
  padding: 0 10px; /* Add padding to align with comments */
}

.comment-count-text {
  text-align: left; /* Align to left */
  font-weight: 600;
  font-size: 14px;
  color: var(--color-text-primary);
  text-transform: uppercase;
  margin: 0; /* Reset margin */
}

.sort-button {
  background: none;
  border: 1px solid var(--color-outline);
  color: var(--color-text-secondary);
  padding: 4px 10px;
  border-radius: 15px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: background-color 0.2s, color 0.2s;

  .material-icons {
    font-size: 16px;
  }

  &:hover {
    background-color: var(--color-active-surface);
    color: var(--primary);
  }
}

#comments-wrapper {
  overflow-y: auto;
  flex: 1;
  padding: 0 10px 20px; /* Add padding to bottom of comments wrapper for spacing */
  margin-bottom: 20px; /* Add margin below comments for additional separation from the input */
  display: flex;
  flex-direction: column;
}

.no-comment-text {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--color-text-secondary);
  text-align: center;
  font-style: italic;
  padding: 15px 0;
}

.comment-wrapper {
  padding: 12px 0;
  border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.08));
  margin-bottom: 5px; /* Keep small margin between comments */
}

.comment-wrapper:last-child {
   border-bottom: none; /* Remove border from last comment */
   padding-bottom: 30px; /* Extra padding on last comment */
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.comment-user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.comment-author {
  font-size: 14px;
  font-weight: 600;
  color: var(--color-text-primary);
}

.comment-date {
  font-size: 12px;
  color: var(--color-text-secondary);
}

.comment-badge {
  color: var(--warning, #FF9B00);
}

.comment-body {
  margin: 8px 0;
}

.comment-text {
  font-size: 14px;
  line-height: 1.5;
  color: var(--color-text-primary);
  text-align: left;
  margin: 0;
  word-break: break-word;
}

.comment-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.comment-actions {
  display: flex;
  align-items: center;
}

.comment-vote-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  color: var(--color-text-secondary);
  transition: all 0.2s;
  margin-right: 5px;
  
  &:hover {
    background-color: var(--color-active-surface);
    color: var(--primary);
  }
  
  &.upvote:hover {
    color: var(--success, #4CAF50);
  }
  
  &.downvote:hover {
    color: var(--error, #F44336);
  }
  
  .material-icons {
    font-size: 18px;
  }
}

.comment-score {
  font-size: 14px;
  color: var(--color-text-secondary);
  
  .score-value {
    font-weight: 600;
    color: var(--color-text-primary);
  }
}

#comment-writer {
  position: absolute; /* Revert back to absolute positioning */
  bottom: 0; /* Re-add positioning */
  left: 0;
  right: 0;
  padding: 15px 20px;
  background-color: var(--color-background);
  border-top: 1px solid var(--color-outline, rgba(0, 0, 0, 0.1));
  /* Remove bottom radius as it's now part of the main container flow */
  /* border-radius: 0 0 10px 10px; */
  z-index: 10;
  box-sizing: border-box; /* Add box-sizing */
}

.comment-box {
  display: flex;
  align-items: center;
  gap: 10px;
}

.comment-textarea {
  flex: 1;
  border-radius: 20px;
  border: 1px solid var(--color-outline);
  padding: 8px 15px;
  min-height: 40px; /* Use min-height */
  height: auto;    /* Allow height to grow */
  line-height: 1.4; /* Adjust line-height for better readability */
  resize: none;
  font-family: inherit;
  background-color: var(--color-surface);
  color: var(--color-text-primary);
  transition: all 0.2s;
  width: 100%;
  
  &:focus {
    border-color: var(--primary);
    background-color: var(--color-active-surface);
    outline: none;
  }
}

.action-btn {
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  padding: 0 16px;
  height: 40px;
  min-height: 40px; /* Ensure button height is consistent */
  transition: all 0.2s ease;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  box-sizing: border-box; /* Add box-sizing */
}

.primary-btn {
  background-color: var(--primary, #00aedd);
  color: white;
  min-width: 110px;
  
  &:hover {
    background-color: var(--color-muted, #BFC2CD);
  }
}

.not-available-data-container {
  max-width: 600px;
  margin: 30px auto;
  padding: 20px;
  text-align: center;
  background-color: var(--color-surface);
  border-radius: 8px;
}

.not-available-data {
  color: var(--warning, #FF9B00);
  font-size: 16px;
  margin-bottom: 15px;
}

.link-to-store {
  color: var(--primary, #00aedd);
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
  
  &:hover {
    text-decoration: underline;
  }
}

/* Mobile responsiveness */
@media (max-width: 767px) {
  .content-container {
    padding: 10px 10px 130px; /* Even more padding for mobile */
    width: 95%;
  }
  
  #comments-wrapper {
    padding: 0 5px 30px; /* More bottom padding on mobile */
    margin-bottom: 30px; /* More bottom margin on mobile */
  }
  
  .comment-box {
    flex-direction: column;
    gap: 10px;
  }
  
  .comment-textarea {
    width: 100%;
  }
  
  .action-btn {
    width: 100%;
    min-width: auto; /* Override desktop min-width */
  }
  
  .comments-header-controls {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding: 0 5px; /* Adjust padding */
  }
  
  .comment-count-text {
    text-align: left;
  }
  
  .sort-button {
    align-self: flex-end; /* Move sort button to the right on mobile */
  }
}
