





































































































































































































































































































































































































.connect-btn,
.send-order-btn {
  border: none;
  border-radius: 20px;
  background-color: #00aedd;
  color: white;
  cursor: pointer;
  width: 170px;
  height: 40px;
}

.open-account-text {
  color: #1f7aa0;
  font-weight: bold;
  text-decoration: none;
}

.legal-warnings-text,
.terms-of-use-text {
  text-decoration: none;
  color: #00aedd;
}

.difbroker-text {
  text-decoration: none;
  color: #1f7aa0;
  font-weight: bold;
}

.ticker-container {
  background-color: var(--custom-color-right-menu-highlight);
  width: 250px;
  height: 30px;
  margin: auto;
  padding-top: 10px;
}

.disconnected-container {
  background-color: #df2f4f;
  color: white;
  text-align: center;
  border-radius: 30px;
  margin: 0 40px 0 40px;
}

.ticker-info-container span {
  color: #898d9d;
}

.separator {
  border-top: 1px solid #898d9d;
  opacity: 0.3;
  width: 90%;
  margin: auto;
  margin-top: 35px;
}

.send-order-container {
  margin-top: 40px;
}

.disconect-broker-btn {
  color: #df2f4f;
  border: none;
  cursor: pointer;
  background-color: transparent;
  font-size: 15px;
}

.disconnect-broker-container {
  margin-top: 20px;
  margin-left: 110px;
}

.select-style {
  width: 350px;
  margin: auto;
}

.ticker-info-container {
  margin-top: 40px;
}

.account-title-container {
  margin-top: 30px;
}

.connected-container {
  background-color: #57a869;
  text-align: center;
  border-radius: 30px;
  margin: 10px 40px 0px 40px;
}

.info-ticker span {
  color: #898d9d;
}

.header-divs-container {
  display: inline-flex;
}

.close_btn {
  background-color: transparent;
  border: none;
  color: white;
  margin-top: 10px;
  margin-left: 30px;
  cursor: pointer;
}

.container-broker-connection {
  display: inline-flex;
  margin-top: 30px;
  margin-left: 30px;
}

.checkbox-style {
  margin-top: 30px;
  transform: scale(1.5);
}

.info-text-container {
  margin-left: 20px;
  margin-right: 10px;
}


.edit-btn {
  border: none;
  background-color: transparent;
  font-weight: bold;
  color: #0e5f85;
  cursor: pointer;
  margin-top: 10px;
}

.send_order_btn_style {
  border: none;
  border-radius: 20px;
  background-color: #00aedd;
  color: white;
  cursor: pointer;
  width: 170px;
  height: 40px;
  font-weight: bold;
}

.send-order-info-container {
  margin-top: 40px;
}

.info-ticker {
  margin-top: 50px;
}

.info-text-style {
  text-align: left;
  font-size: 12px;
}

.btns-container {
  margin-top: 30px;
}

a {
  color: #0fafdb;
}

#broker-connection span,
#broker-connection h3,
#broker-connection p {
  color: var(--custom-color-right-menu-font);
}

#broker-connection {
  min-height: 500px;
}

.header-container {
  display: flex;
  justify-content: center;
  padding: 10px 0px 20px 0px !important;
}

.title-container {
  margin-left: 30px;
  font-size: 20px;
  font-weight: 600;
}

.close-btn-container {
  margin-left: auto;
  order: 2;
  margin-right: 10px;
}

.not-available-data-container {
  text-align: center;
}

.not-available-data {
  color: #FF9B00 !important;
}

.link-to-store {
  color: #35add8;
}

.info-ticker-box {
  width: 100%;
  display: flex;
  justify-content: center;
}

.text-info-ticker {
  text-align: center;
  background-color: #F3F4F7;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 16px;
  padding: 7px 25px;

  span {
    font-size: 18px;
  }
}

.text-bold {
  font-weight: bold;
}
