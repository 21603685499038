




























































































































































































































































































































































































































































































































































































































































































































































































































$base_background_color: var(--color-background-surface);
$background_casillas: var(--color-background-background-casillas);

#fullscreen-button-container {
  border-left: 1px solid var(--color-custom-only-light-theme-border);
  order: 4;
  height: 100%;
  width: 81px;
  text-align: center;
  flex-shrink: 0;
}

#fullscreen-button-container:hover {
  background-color: var(--primary-100);
  cursor: pointer;
}

#fullscreen-button-container span {
  font-size: 30px;
  line-height: 77px;
  color: var(--color-typography-on-background) !important;
}

.chart-page {
  z-index: 4 !important;
  overflow:hidden;
}
hr{
  background-color: red;
}
.tools-container {
  border-left: 1px solid var(--color-custom-only-light-theme-border);
  box-shadow: 1px 0 3px rgba(0, 0, 0, 0.25);
  order: 3;
  display: flex;
}
.group-tools {
  display: flex;
  flex-grow: 1;
  order: 3;
  flex-direction: row;
  overflow-x: auto;
}
.cartera {
  order: 1;
  width: 160px;
  height: 100%;
  padding: 0 5px;
  flex-shrink: 0;
  box-shadow: 1px 0 3px rgba(0, 0, 0, 0.25);
  border-right: 1px solid var(--color-custom-only-dark-theme-border);
}
#ai {
  background-color: $base_background_color;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  position: relative;
  overflow:visible;
}
#nav-tools {
  height: 77px;
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-y: show;
  height: var(--colo-custom-stockchart-ai-height);
  border-bottom: 1px solid var(--color-custom-only-dark-theme-border);
}
.opciones {
  height:80%;
  position:absolute;
  z-index: 6;
  margin-top:69px;
  float: left;
}
.last-one {
  position: absolute;
  display: grid;
  z-index: 999999;
}
.white-window {
  float: left;
  width: 130px;
}
.tool {
  width: 80px;
  height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 5px;
  margin: 9px 5px 0 0;
  display: inline-block;
  background-color: var(--custom-color-stockchart-preferences-bg);
}

.tool-mobile {
  width: 96px;
  height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  margin: 9px 5px 0 0;
  display: inline-block;
  background-color: $background_casillas;
}
.tool-name {
  font-size: 15px;
  width: 80%;
  margin-left: 10px;
  text-align: center;
}

#tool-name-container {
  width: 100%;
  height: 40%;
  background: var(--custom-color-stockchart-preferences-title);
  border-radius: 4px 4px 0px 0px;
  display: inline-flex;
  color: var(--custom-color-stockchart-preferences-text);
}

#tool-description-container {
  text-align: center;
  font-size: 12px;
  color: var(--custom-color-stockchart-preferences-inside);
  margin-top: 8px;
}
#add-tools {
  margin: 9px 5px;
  float: right;
  width: 50px;
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--custom-color-add-tools);
  border-radius: 50px;
  margin-top: 15px;
}
#add-tools-mobile {
  margin: 9px 5px;
  float: right;
  width: 80px;
  height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  background-color: $background_casillas;
}
.name-to-reduce{
  font-size: 11px;
}
.tool-container {
  order: 1;
  flex-grow: 1;
  white-space: nowrap;
  text-align: end;
  overflow-x: auto;
  overflow-y: hidden;  
}
@media screen and (min-width: 900px) {
  .tool-container{
    margin-left: 113px;
  }
}
#cartera-element {
  margin-top: 15px !important;
}
.buttons-container{
  display: inline-flex;
  order:2;
  text-align: center;
  margin-left:15px;
}
.show_chart_container{
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.show_chart_container button.active{
  background-color: #EDF8FB; 
}

.show_chart_container button{
  border-radius: 50%;
  height: 44px;
  width: 45px;
  background-color: #f3f4f7;
  border-style: solid;
  border: 0.5px solid grey;
}

.drag_handle_container{
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-left:50px;
}

.drag_handle_container button{
  border-radius: 50%;
  height: 44px;
  width: 45px;
  background-color: #f3f4f7;
  border-style: solid;
  border: 0.5px solid grey;
}

.drag_handle_container button.active{
  background-color: #EDF8FB; 
}

.no-eureker-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #E39B0F33;
  width: 100%;
  text-align: center;
  margin: 7px;
  border: 2px solid #E39B0F;
  border-radius: 5px;
}

.ticker-no-eurekers{
  font-weight: bold;
  margin-right: 5px;
}

.add-icon{
  margin-top: 9px;
}

.center{
  text-align:center;
  cursor:pointer;
  background-color: #f3f4f7;
  border-style: solid;
  border-radius: 70%;
  border: 0.5px solid grey;
  height: 40px;
  width: 41px;
  margin-right:5px;
}
.center:hover{
  background-color: #EDF8FB;
}

.center-container{
  display: flex;
  justify-content: center;
  align-items: center;  
}

.not-allowed-data{
  color: #FF9B00;
}

#tool-description-container-not-allowed{
  margin-top: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#texto-no-eureker span {
  color: var(--color-text-secondary);
}

